function getHeaders(table) {
  return table.header.map(item => {
    return { text: item.data, value: item.data }
  })
}

export function getTotal(table) {
  if (table.total) {
    let total = table.total.map((item, index) => {
      return [
        getHeaders(table)[index].text,
        item
          ? isNaN(parseFloat(item.data))
            ? item.data
            : parseFloat(item.data.replace(/,/g, ''))
          : item
      ]
    })
    return Object.fromEntries(total)
  }
}

export function getItems(table) {
  let table_matrix_matrix = []
  //Inica matriz vacia
  let table_matrix = []

  //Por cada seccion en table
  table?.sections.forEach(section_element => {
    //Arreglo Vacio de Seccion, contiene un objeto por fila
    let section = []

    //SubHeader
    //Se construye una fila con el valor SubHeader si existe
    if (
      section_element.subHeader &&
      Array.isArray(section_element.subHeader) &&
      section_element.subHeader.length > 0
    ) {
      let sub_header = section_element.subHeader.map((item, index) => {
        return [getHeaders(table)[index].text, item ? item.data : item]
      })
      section.push(Object.fromEntries(sub_header))
    }

    // Filas
    section_element.rows?.map(row_element => {
      let tier = []
      row_element.row.forEach((item, index) => {
        let row = null
        if (typeof item.data === 'number') {
          row = parseFloat(item.data)
        } else {
          row = item.data
          let reg = row.search(/[A-Za-z]/g)
          if (reg === -1 && !row.includes('-', 4) && !isNaN(parseFloat(row))) {
            row = parseFloat(row.replace(/,/g, ''))
          }
        }  
        tier.push([getHeaders(table)[index].text, row])
      })
      section.push(Object.fromEntries(tier))
    })

    //SubTotal
    //Se construye una fila con el valor SubTotal si este hubiere
    if (
      section_element.subTotal &&
      Array.isArray(section_element.subTotal) &&
      section_element.subTotal.length > 0
    ) {
      let sub_total = section_element.subTotal.map((item, index) => {
        return [
          getHeaders(table)[index].text,
          item
            ? isNaN(parseFloat(item.data))
              ? item.data
              : parseFloat(item.data.replace(/,/g, ''))
            : item
        ]
      })
      section.push(Object.fromEntries(sub_total))
    }

    //Se envia la seccion al arreglo bidimensional table_matrix
    table_matrix.push(section)
    table_matrix.flat(Infinity)
  })

  table_matrix_matrix.push(table_matrix.flat())
  return table_matrix_matrix
}
