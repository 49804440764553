import BaseService from './base'
import axios from '@/plugins/axios'

export default class AccountStatementService extends BaseService {
  static resource() {
    return 'api/v1/account_statements'
  }

  static async download(params) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/download`,
      {
        params: params
      }
    )
    return data
  }
}
