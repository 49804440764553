<template>
  <div>
    <base-table
      :headers="headers"
      :items="table_matrix"
      disable-pagination
      hide-default-footer
      disable-sort
      height="100%"
      dense
    >
      <template #item="{ item }">
        <tr>
          <td
            v-for="(cell, i) in item"
            :key="i"
            :class="[tdHandleStyle(cell), `text-${cell.alignCell}`]"
            :colspan="colspanHandler(cell, i)"
            :style="displayHandler(cell, i)"
          >
            <!-- EMPTY CELL -->
            <template v-if="cell.dataActionLink && cell.dataActionLink.externalLink === null">
              <span></span>
            </template>
            <template v-else>
              <span v-if="!cell.dataActionLink">
                {{ cell.data }}
              </span>
              <span v-else>
                <a class="text-decoration-underline" @click="showModal(cell)">
                  {{ cell.data }}
                </a>
              </span>
            </template>
          </td>
        </tr>
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseTable from '@/components/commons/BaseTable'
import Modal from './Modal'
export default {
  components: { BaseTable },
  props: {
    table: {
      type: Object,
      required: true
    }
  },
  computed: {
    headers() {
      return this.table?.header.map(item => {
        return { text: item.data, value: item.data, align: item.columnAlignment }
      })
    },
    table_matrix() {
      //Inica matriz vacia
      let table_matrix = []

      //Por cada seccion en table
      this.table?.sections.forEach(section_element => {
        //Arreglo Vacio de Seccion, contiene un objeto por fila
        let section = []

        //SubHeader
        //Se construye una fila con el valor SubHeader si existe
        if (section_element.subHeader) {
          let sub_header = section_element.subHeader.map((item, index) => {
            return [
              this.headers[index].text,
              {
                data: item ? item.data : item,
                dataType: item ? item.dataType : item,
                dataActionLink: item ? item.dataActionLink : item,
                rowType: 'subHeader',
                alignCell: this.headers[index].align ? this.headers[index].align : this.getRowAlign(item)
              }
            ]
          })
          section.push(Object.fromEntries(sub_header))
        }

        // Filas
        section_element.rows?.forEach(section_element => {
          let tier = []
          section_element.row.map((item, index) => {
            tier.push([
              this.headers[index].text,
              {
                data: this.dataRowHandler(item),
                dataType: item ? item.dataType : item,
                dataActionLink: item ? item.dataActionLink : item,
                rowType: 'row',
                alignCell: this.headers[index].align ? this.headers[index].align : this.getRowAlign(item)
              }
            ])
          })

          section.push(Object.fromEntries(tier))
        })

        //SubTotal
        //Se construye una fila con el valor SubTotal si este hubiere
        if (section_element.subTotal) {
          let sub_total = section_element.subTotal.map((item, index) => {
            return [
              this.headers[index].text,
              {
                data: item ? item.data : item,
                dataType: item ? item.dataType : item,
                dataActionLink: item ? item.dataActionLink : item,
                rowType: 'subTotal',
                alignCell: this.headers[index].align ? this.headers[index].align : this.getRowAlign(item)
              }
            ]
          })
          section.push(Object.fromEntries(sub_total))
        }
        //Se envia la seccion al arreglo bidimensional table_matrix
        table_matrix.push(section)
      })

      //Total
      // Se contruye una fila con el atributo total de la tabla si hubiera fila de total
      if (this.table.total) {
        let total = this.table.total.map((item, index) => {
          return [
            this.headers[index].text,
            {
              data: item ? item.data : '-',
              dataType: item ? item.dataType : item,
              dataActionLink: item ? item.dataActionLink : item,
              rowType: 'Total',
              alignCell: this.headers[index].align ? this.headers[index].align : this.getRowAlign(item)
            }
          ]
        })
        table_matrix.push(Object.fromEntries(total))
      }

      //Se retorna matriz llena
      return table_matrix.flat()
    }
  },
  methods: {
    tdHandleStyle(cell) {
      let styles = ''
      switch (cell.rowType) {
        case 'subHeader':
          styles = `font-weight-bold subHeader`
          break
        case 'subTotal':
          styles = `font-weight-bold subTotal`
          break
        case 'Total':
          styles = `font-weight-bold total`
      }
      return styles
    },
    colspanHandler(cell,i) {
      if (cell.rowType === 'subHeader' && this.headers[0].text === i && this.$vuetify.breakpoint.mdAndUp ) {
        return 2
      } else {
        return 1
      }
    },
    displayHandler(cell, i) {
      if (cell.rowType === 'subHeader' && this.headers[1].text === i) {
        return 'display: none;'
      }
    },
    async showModal(item) {
      if (
        item.dataActionLink?.externalLink &&
        item.dataActionLink.externalLink !== '-'
      ) {
        window.open(item.dataActionLink.externalLink, '_blank')
      } else if (item.dataActionLink?.endpoint) {
        const params = {
          fullscreen: this.$vuetify.breakpoint.smAndDown,
          width: '70%',
          transition: 'dialog-top-transition',
          item: item,
          showClose: false
        }
        await this.$dialog.showAndWait(Modal, params)
      }
    },

    getRowAlign(item) {
      return item && item.data !== null && item.dataType === 'number' ? 'right' : 'left'
      // return item?.dataType === 'number' && item.data ? 'right' : 'left'
    },

    dataRowHandler(item) {
      // if (item.dataType === 'number') {
      //   return item && item.data !== null ? item.data : '-'
      // }
      return item && item.data !== null ? item.data : '-'
    }
  }
}
</script>
<style lang="sass" scoped>
.bt
  border-top: 1px solid black
.bb
  border-bottom: 1px solid black !important
.subHeader
  color: var(--v-primary-base) 
  background-color: #FFFFFF !important
  border-top: 1px solid var(--v-primary-base)
  border-bottom: 1px solid var(--v-primary-base) !important
  text-transform: uppercase
.subTotal
  color: var(--v-primary-base) 
  background-color: #FFFFFF !important
  border-top: 1px solid var(--v-primary-base)
  border-bottom: 1px solid var(--v-primary-base) !important
  text-transform: uppercase
.total
  background-color: var(--v-primary-base)
  color: #FFFF 
  text-transform: uppercase

</style>
