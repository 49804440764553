<template>
  <base-form-modal
    :title="$t('portal_invoices')"
    :loading="loading"
    hide-submit
    @close="$emit('submit', null)"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('portal_account_status_year')"
            :rules="{
              required: true,
              length: 4,
              max_value: currentYear,
              min_value: 2012
            }"
            v-slot="{ errors }"
          >
            <v-select
              v-model="year"
              :items="years"
              :label="$t('portal_account_status_year')"
              :error-messages="errors[0]"
              hide-details="auto"
              @input="handleYear"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('portal_account_status_month')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="month"
              :items="allowedMonths"
              :label="$t('portal_account_status_month')"
              item-value="code"
              item-text="month"
              :error-messages="errors[0]"
              hide-details="auto"
              @input="handleMonth"
            >
              <template #item="{ item }">
                <span>{{ $t(item.month) }}</span>
              </template>

              <template #selection="{ item }">
                <span>{{ $t(item.month) }}</span>
              </template>
            </v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            class="table-invoice-modal"
            :headers="headers"
            :items="items"
            :items-per-page="999"
            :no-data-text="$t('general_no_data_label')"
            item-key="name"
            hide-default-footer
            show-select
            mobile-breakpoint="0"
          >
            <template v-slot:item.action="{ item }">
              <div
                class="d-flex flex-row justify-end justify-md-center action-container"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <a v-on="on" class="mr-4" @click="handleViewPDF(item.pdf)">
                      <v-icon color="primary" v-text="'mdi-file-pdf-box'" />
                    </a>
                  </template>
                  <span>{{ $t('portal_invoice_view_pdf') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <a v-on="on" @click="handleViewXML(item.xml)">
                      <v-icon color="primary" v-text="'mdi-file-document'" />
                    </a>
                  </template>
                  <span>{{ $t('portal_invoice_view_xml') }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer-custom-button-after>
      <v-btn
        @click="handleDownload"
        color="primary"
        :disabled="selected.length === 0"
        :loading="downloading"
      >
        <span v-if="selected.length > 0">
          {{ $t('btn_download') }} ({{ selected.length }})
        </span>
        <span v-else>
          {{ $t('btn_download') }}
        </span>
      </v-btn>
    </template>
  </base-form-modal>
</template>

<script>
import BaseFormModal from '@/components/commons/BaseFormModal'
import { mapState } from 'vuex'
import InvoiceService from '@/services/invoice'
import { getObjectURLFromBase64 } from '@/utils'

export default {
  components: { BaseFormModal },
  data() {
    return {
      selected: [],
      loading: false,
      downloading: false,
      years: [],
      year: null,
      month: '',
      months: [
        { code: '01', month: 'portal_month1' },
        { code: '02', month: 'portal_month2' },
        { code: '03', month: 'portal_month3' },
        { code: '04', month: 'portal_month4' },
        { code: '05', month: 'portal_month5' },
        { code: '06', month: 'portal_month6' },
        { code: '07', month: 'portal_month7' },
        { code: '08', month: 'portal_month8' },
        { code: '09', month: 'portal_month9' },
        { code: '10', month: 'portal_month10' },
        { code: '11', month: 'portal_month11' },
        { code: '12', month: 'portal_month12' }
      ],
      invoices: [],
      headers: [
        {
          text: this.$t('portal_invoice_number'),
          align: 'start',
          value: 'number',
          width: '100%',
          sortable: false,
          class: 'invoices-modal-header'
        },
        /*
        {
          text: 'Fecha',
          align: 'center',
          value: 'date',
          width: '100%',
          sortable: false,
          class: 'invoices-modal-header'
        },
        */
        {
          text: this.$t('general_actions_label'),
          align: 'center',
          sortable: false,
          value: 'action',
          class: 'invoices-modal-header'
        }
      ]
    }
  },
  computed: {
    ...mapState('account', ['account']),

    currentYear() {
      return parseInt(this.$date().format('YYYY'))
    },
    lastYear() {
      return parseInt(
        this.$date()
          .subtract(1, 'year')
          .format('YYYY')
      )
    },
    currentMonth() {
      return this.$date().format('MM')
    },
    lastMonth() {
      return this.$date()
        .subtract(1, 'month')
        .format('MM')
    },
    allowedMonths() {
      let filteredMonths = this.months
      if (this.year == this.currentYear)
        filteredMonths = filteredMonths.filter(
          item => item.code < this.currentMonth
        )

      return filteredMonths
    },
    items() {
      return this.invoices
      // return this.invoices.filter(item => item.month === this.month)
    }
  },
  async created() {
    this.getAvailableYears()

    if (this.currentMonth == 1) {
      this.year = this.lastYear
      this.month = '12'
    } else {
      this.year = this.currentYear
      this.month = this.lastMonth
    }

    this.getData()
  },
  methods: {
    async handleViewPDF(base64) {
      const fileURL = getObjectURLFromBase64(base64, 'application/pdf;base64')
      window.open(fileURL, '_blank')
    },

    async handleViewXML(base64) {
      const fileURL = getObjectURLFromBase64(base64, 'application/xml;base64')
      window.open(fileURL, '_blank')
    },

    async getData() {
      // Check Valid year and month
      if (!this.year || !this.month) {
        this.invoices = []
        return false
      }

      this.loading = true

      const params = {
        'filter[accountCode]': this.account?.code,
        'filter[year]': this.year,
        'filter[month]': this.month
      }
      try {
        const { data } = await InvoiceService.fetch(params)
        this.invoices = data
      } catch (error) {
        console.log(error)
        this.$dialog.message.error(this.$t('portal_invoice_data_error'))
      } finally {
        this.loading = false
      }
    },

    async handleDownload() {
      this.downloading = true
      for (const item of this.selected) {
        const namePDF = `${item.name}.pdf`
        const nameXML = `${item.name}.xml`
        await this.downloadPDF(item.pdf, namePDF)
        await this.downloadXML(item.xml, nameXML)
      }
      this.downloading = false
      this.selected = []
    },
    async downloadPDF(base64, name) {
      try {
        const url = getObjectURLFromBase64(base64, 'application/pdf;base64')
        const a = document.createElement('a')
        a.href = url
        a.setAttribute('download', name)
        document.body.appendChild(a)
        // Trigger a click event on the link element to initiate the download
        a.click()

        // Clean up by revoking the blob URL and removing the link element
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      } catch (error) {
        console.error('Failed to download the PDF file: ', error)
      }
    },
    async downloadXML(base64, name) {
      try {
        const url = getObjectURLFromBase64(base64, 'application/xml;base64')
        const a = document.createElement('a')
        a.href = url
        a.setAttribute('download', name)
        document.body.appendChild(a)
        a.click()

        // Clean up by revoking the blob URL and removing the link element
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      } catch (error) {
        console.error('Failed to download the XML file: ', error)
      }
    },
    handleYear() {
      this.month = ''
      this.selected = []
      this.invoices = []
    },
    handleMonth() {
      this.selected = []
      this.getData()
    },
    getAvailableYears() {
      let year = 2012
      for (year; year <= this.currentYear; year++) {
        this.years.push(year)
      }
    }
  }
}
</script>

<style lang="sass">
.table-invoice-modal
  th
    vertical-align: middle

.invoices-modal-header
  vertical-align: middle
</style>
