import BaseService from './base'
import axios from '@/plugins/axios'

export default class InvoiceService extends BaseService {
  static resource() {
    return 'api/v1/invoices'
  }

  static async downloadPDF(params) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/download-pdf`,
      {
        params: params
      }
    )
    return data
  }
}
