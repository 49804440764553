<template>
  <div class="graph-container">
    <div style="display: none;">
      <code>Etiquetas x:{{ mixedChart.xaxis.pointvalues.length }}</code>
      <br />
      <code
        >Etiquetas series 1: {{ mixedChart.series[0].pointvalues.length }}</code
      >
      <code
        >Etiquetas series 2: {{ mixedChart.series[1].pointvalues.length }}</code
      >
      <code
        >Etiquetas series 3: {{ mixedChart.series[2].pointvalues.length }}</code
      >
      <code
        >Etiquetas series 4: {{ mixedChart.series[3].pointvalues.length }}</code
      >
    </div>
    <mixed-bar-line-chart-graph :mixedChartParams="chartAssembly" />
  </div>
</template>
<script>
import MixedBarLineChartGraph from '@/views/home/partials/MixedBarLineChartGraph'
export default {
  components: { MixedBarLineChartGraph },
  props: {
    mixedChart: {
      type: Object
    }
  },
  computed: {
    chartAssembly() {
      let mixedChartParams = {
        type: 'bar',
        data: {
          labels: this.mixedChart.xaxis.pointvalues,
          datasets: [
            {
              label: this.mixedChart.series[0].serieslabel,
              type: 'bar',
              backgroundColor: '#75748a',
              data: this.mixedChart.series[0].pointvalues,
              fill: false,
              yAxisID: 'id1',
              order: 2
            },
            {
              label: this.mixedChart.series[1].serieslabel,
              type: 'bar',
              backgroundColor: '#3e95cd',
              data: this.mixedChart.series[1].pointvalues,
              fill: false,
              yAxisID: 'id1',
              order: 2
            },
            {
              label: this.mixedChart.series[2].serieslabel,
              type: 'bar',
              backgroundColor: '#eb3e05',
              data: this.mixedChart.series[2].pointvalues,
              fill: false,
              yAxisID: 'id1',
              order: 2
            },
            {
              label: this.mixedChart.series[3].serieslabel,
              type: 'line',
              // pointBorderColor: '#03037c',
              pointBackgroundColor: '#ebcc05',
              borderColor: '#ebcc05',
              // pointStyle: 'line',
              pointRadius: 2.5,
              tension: 0.4,
              fill: false,
              data: this.mixedChart.series[3].pointvalues,
              yAxisID: 'id2',
              order: 1
            }
          ]
        },
        options: {
          title: {
            display: true,
            text: this.mixedChart.title + ' - ' + this.mixedChart.subtitle,
            fontFamily: 'Lato',
            fontColor: '#00559e',
            fontStyle: '700',
            fontSize: this.$vuetify.breakpoint.mdAndUp ? 22 : 14
          },
          legend: {
            display: true,
            fullWidth: true,
            labels: {
              fontFamily: 'Lato',
              padding: this.$vuetify.breakpoint.mdAndUp ? 10 : 10,
              fontSize: this.$vuetify.breakpoint.mdAndUp ? 16 : 12,
              fontWeight: 'bold',
              boxWidth: 20,
              boxHeight: 20
            },
            onHover: function(e) {
              e.target.style.cursor = 'pointer'
            }
          },
          hover: {
            onHover: function(e) {
              let point = this.getElementAtEvent(e)
              // console.log('dan', point[0]['_chart'])
              if (point.length && point[0] === undefined)
                e.target.style.cursor = 'pointer'
              else e.target.style.cursor = 'default'
            }
          },
          tooltips: {
            callbacks: {
              title: (tooltipItems, data) => {
                return (
                  tooltipItems[0].xLabel +
                  ' - ' +
                  data.datasets[tooltipItems[0].datasetIndex].label
                )
              },
              label: item => {
                if (item.value < 100) {
                  let value = new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 2
                  }).format(item.value)
                  return value + ' ' + '%'
                } else {
                  let value = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(item.value)
                  return value
                }
              }
            }
          },
          elements: {
            line: {
              // fill: false
            },
            point: {
              // hoverRadius: 7,
              // radius: 4
              // pointBackgroundColor: '#3e95cd',
              // backgroundColor: 'rgba(151,187,205,1)'
            }
          },
          scales: {
            yAxes: [
              {
                display: this.$vuetify.breakpoint.mdAndUp ? true : false,
                position: 'left',
                type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: this.mixedChart.series[0].seriesunit,
                  beginAtZero: true,
                  fontFamily: 'Lato'
                },
                id: 'id1',
                ticks: {
                  callback: function(label) {
                    let value = new Intl.NumberFormat('en-US', {
                      maximumSignificantDigits: 2
                    }).format(label)
                    return value
                  }
                }
              },
              {
                scaleLabel: {
                  display: true,
                  labelString: this.mixedChart.series[3].seriesunit,
                  beginAtZero: true,
                  fontFamily: 'Lato'
                },
                display: this.$vuetify.breakpoint.mdAndUp ? true : false,
                type: 'linear',
                position: 'right',
                gridLines: {
                  display: false
                },
                id: 'id2',
                ticks: {
                  callback: function(label) {
                    // let value = new Intl.NumberFormat('en-US', {
                    //   maximumSignificantDigits: 2
                    // }).format(label)
                    // label.toFixed(2)
                    // (Math.round(label * 100) / 100).toFixed(2)
                    return (Math.round(label * 100) / 100).toFixed(2) + '%'
                  }
                }
              }
            ],
            xAxes: [
              {
                display: true,
                // type: 'linear',
                scaleLabel: {
                  display: true,
                  labelString: this.mixedChart.xaxis.label,
                  fontFamily: 'Lato'
                },
                gridLines: {
                  display: false
                }
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 2 / 3
        },
        defaults: {
          font: 'Lato'
        }
      }
      return mixedChartParams
    },
    widthHandler() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false
        case 'sm':
          return false
        case 'md':
          return true
        case 'lg':
          return true
        case 'xl':
          return true
        default:
          return true
      }
    },
    heightHandler() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 800
        case 'sm':
          return 500
        case 'md':
          return 300
        case 'lg':
          return 300
        case 'xl':
          return 300
        default:
          return 200
      }
    }
  }
}
</script>
<style scoped>
.graph-contianer {
  min-width: 300px;
}
.test {
  display: none;
}
</style>
