<template>
  <div id="home" class="pt-0 mt-2 pa-0">
    <v-container grid-list-xl fluid class="pt-0 mt-0 pa-0">
      <v-row>
        <!-- ACCOUNT SWITCH -->
        <v-col id="account-switch" cols="12" lg="4">
          <account-switch
            min-height="125px"
            max-height="125px"
            :items="accounts"
            @accountSwitch="handleAccountSwitch()"
          />
        </v-col>
        <!-- ACCOUNT TOTAL -->
        <v-col id="account-total" cols="12" lg="8" class="pb-0">
          <account-total min-height="125px" max-height="auto" />
        </v-col>

        <!-- PRODUCTS -->
        <v-col cols="12" class="pt-5">
          <product
            :product="product"
            :indexProp="refresh"
            v-if="genericProductValidator"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AccountTotal from '@/components/widgets/AccountTotal'
import AccountSwitch from '@/components/widgets/AccountSwitch'
import GenericProductService from '@/services/genericProduct'
// import genericProduct from '@/api/genericProduct'
import Product from './Product'

import { mapState, mapActions } from 'vuex'
export default {
  components: { AccountTotal, AccountSwitch, Product },
  data() {
    return {
      totals: {},
      product: {},
      refresh: false
    }
  },
  async created() {
    if (this.account) await this.populateProducts()
  },
  computed: {
    ...mapState('account', ['account', 'accounts']),
    ...mapState('language', ['languages', 'idiom']),
    genericProductValidator() {
      return (
        this.product.tables && this.product.productTabs && this.product.products
      )
    }
  },
  watch: {
    async account() {
      await this.populateProducts()
    },
    async idiom() {
      await this.populateProducts()
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('account', ['setProducts']),
    async populateProducts() {
      if (this.account.code) {
        this.setLoading(true)
        try {
          const params = {
            accountCode: this.account.code,
            lang: this.idiom.code
          }
          const { data } = await GenericProductService.fetch(params)
          this.product = data
          this.setProducts(data)
        } catch (error) {
          if (error.response.status !== 401)
            this.$dialog.message.error('No se pudo cargar la información.')
        } finally {
          this.setLoading(false)
        }
      }
    },
    handleAccountSwitch() {
      this.refresh = !this.refresh
    }
  }
}
</script>
<!--
  /* .v-application {
  padding-top: 20px !important;
  margin-top: 20px !important;
} */
.container {
  padding: 0;
}
/*

.main-container {
  display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 15px;
}

@media only screen and (max-width: 1264px) {
  .main-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.main-container {
    grid-template-columns: 1fr 2fr;
    gap: 15px;
  }
  @import '@/sass/variables.scss';

@media print {
  body {
    $font-size-root: 5px;
  }
}
*/

-->
<style scoped></style>
