<script>
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  extends: Doughnut,
  mounted() {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.data, this.options)
  },
  watch: {
    data() {
      this.addPlugin(ChartDataLabels)
      this.renderChart(this.data, this.options)
    }
  }
}
</script>
<style></style>
