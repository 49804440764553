<template>
  <div class="">
    <!-- {{ mixedChartParams }} -->
    <!-- {{widthHandler()}} -->
    <canvas id="planet-chart" width="600" height="400"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js'
// import planetChartData from '@/api/mixedChart'
export default {
  props: {
    mixedChartParams: {
      type: Object
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    }
  },
  mounted() {
    const ctx = document.getElementById('planet-chart')
    new Chart(ctx, this.mixedChartParams)
  }
}
</script>
<style></style>
