<template>
  <v-btn-toggle
    v-model="toggle_chart"
    mandatory
    :class="align"
    color="#01579B"
    dense
    tile
    @change="emitChartSelector"
    background-color="white"
    group
  >
    <!-- <v-btn>
        <v-icon>mdi-square</v-icon>
      </v-btn> -->
    <!-- <v-icon>mdi-id-card</v-icon> -->
    <!-- <v-icon>mdi-trackpad</v-icon> -->
    <!-- <v-icon>mdi-view-compact</v-icon> -->
    <!-- <v-icon>mdi-view-dashboard</v-icon> -->

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" :value="'Treemap'">
          <v-icon>mdi-view-compact</v-icon>
        </v-btn>
      </template>
      <span>Treemap</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" :value="'Dona'">
          <v-icon>mdi-chart-pie</v-icon>
        </v-btn>
      </template>
      <span>Pie</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
export default {
  props: {
    align: {
      type: String,
      required: true
    }
  },
  methods: {
    emitChartSelector() {
      this.$emit('emitChartSelector', this.toggle_chart)
    }
  },
  data() {
    return {
      toggle_chart: 0
    }
  }
}
</script>
<style lang=""></style>
