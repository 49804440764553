<template>
  <div>
    <v-tabs center-active show-arrows v-model="tab">
      <!-- Se coloca dentro de un contenedor el tab para no renderizar tabs sin tablas asociadas, v-container funciona mejor a nivel de estilo que un div -->
      <v-tab v-for="tab in products" :key="tab.index">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab" class="mt-1" v-if="products">
      <div v-for="iterator in products.length" :key="iterator.index">
        <v-tab-item>
          <!-- v-if="
            product.products[selected].verticalInfo[iterator - 1]
              .verticalStack[0].horizontalStack
          " -->
          <!-- iterator comienza en 1 -->
          <div
            v-for="(hstack, index) in products[iterator - 1].verticalStack"
            :key="hstack.index"
          >
            <!-- Tablas -->
            <v-row
              class="mt-4 ml-1 mb-10"
              dense
              v-if="
                hstack.horizontalStack[0].referencedSectionPath === 'tables' &&
                  tableValidator(hstack)
              "
            >
              <v-col cols="12" md="12" class="margin-bottom">
                <!-- Table Title  -->
                <span
                  class="table-title black--text font-weight-bold d-flex flex-row align-center justify-space-between"
                >
                  {{ tableTitleHandler(hstack) }}
                  <export-button
                    v-if="index === 0"
                    :fileName="xlsfileNameHandler"
                    :titles="titleTables"
                    :dataToExport="allItems"
                    :totals="totals"
                    class="d-print-none"
                  />
                </span>
              </v-col>
              <v-col cols="12" md="12" class="mt-0" v-if="true">
                <table-component
                  v-if="true"
                  :table="tablePropHandler(hstack)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- Endpointsources -->
                <generic-source-table
                  :hstack="hstack"
                  :product="product"
                  :endpointSources="endpointSources"
                  v-if="
                    hstack.horizontalStack[0].referencedSectionPath ===
                      'endpointSources' && hstack.horizontalStack[0].referencedId !== 'rendimientoBarsSource'
                  "
                />
              </v-col>
              <v-col v-if="hstack.horizontalStack[0].referencedId == 'rendimientoBarsSource'" cols="12" class="mt-5">
                <performance-bar-chart />
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </div>
      <v-tab-item> </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import TableComponent from '@/views/home/partials/TableComponent'
import ExportButton from '@/components/commons/ExportButton'
//import Activity from '@/views/home/partials/Activity'
import GenericSourceTable from '@/views/home/partials/GenericSourceTable'
import PerformanceBarChart from '@/views/home/partials/PerformanceBarChart'

//import Performance from '@/views/home/partials/Performance'
import { getTotal } from '@/utils/itemsToExport'
import { getItems } from '@/utils/itemsToExport'
import { mapState } from 'vuex'


export default {
  components: {
    TableComponent,
    ExportButton,
    //Activity,
    //Performance,
    GenericSourceTable,
    PerformanceBarChart
  },
  props: {
    product: {
      type: Object
    },
    selected: {
      type: Number
    },
    secondaryTabs: {
      type: [String, Number, Object, Boolean]
    },
    secondaryTabsAux: {
      type: [String, Number, Object, Boolean]
    },
    endpointSources: {
      type: Array
    }
  },
  data() {
    return {
      tab: 0,
      tables: [],
      titleTables: [],
      allItems: [],
      totals: [],
      mixedChart: {},
      firstLoad:  true,
    }
  },
  computed: {
    ...mapState('account', ['account']),

    products() {
      return this.product
        ? this.product.products[this.selected].verticalInfo
        : []
    },

    xlsfileNameHandler() {
      const timestamp = Date.now()
      const humanReadableDateTime = new Date(timestamp).toDateString()
      return `${this.account.code}-${
        this.product.products[this.selected].title
      }-${
        this.product.products[this.selected].verticalInfo[this.tab].title
      }-${humanReadableDateTime}`
    }
  },
  methods: {
    xlsTableHandler() {
      this.tables = []
      let tables_ids = []

      if (this.products[this.tab].verticalStack.length) {
        this.products[this.tab].verticalStack.forEach(element => {
          if (element?.horizontalStack[0].referencedSectionPath === 'tables') {
            tables_ids.push(element.horizontalStack[0].referencedId)
          }
        })
        tables_ids.forEach(element => {
          this.tables.push(
            this.product.tables.find(table => table.id === element)
          )
        })
      }
    },
    xlsTitleTablesHandler() {
      this.titleTables = []
      this.tables.forEach(table => {
        this.titleTables.push([{ title: table.title }])
      })
      this.titleTables.flat(1)
    },
    xlsTotalsHandler() {
      this.totals = []
      this.tables.forEach(table => {
        this.totals.push([getTotal(table)])
      })
      this.totals.flat(1)
    },
    xlsAllItemssHandler() {
      this.allItems = []
      this.tables.forEach(table => {
        this.allItems.push([getItems(table)])
      })
      this.allItems = [...flatten(this.allItems, 2)]
      const seen = new Set()
      this.allItems = this.allItems.filter(el => {
        const duplicate = seen.has(el)
        seen.add(el)
        return !duplicate
      })
      function* flatten(array, depth) {
        if (depth === undefined) {
          depth = 1
        }
        for (const item of array) {
          if (Array.isArray(item) && depth > 0) {
            yield* flatten(item, depth - 1)
          } else {
            yield item
          }
        }
      }
    },
    xlsPropsHandler() {
      if (this.products) {
        this.xlsTableHandler()
        this.xlsTitleTablesHandler()
        this.xlsTotalsHandler()
        this.xlsAllItemssHandler()
      }
    },
    tablePropHandler(hstack) {
      // return this.product.tables[hstack.horizontalStack[0].referencedId]
      return this.product.tables.find(
        table => table.id === hstack.horizontalStack[0].referencedId
      )
    },
    tableTitleHandler(hstack) {
      return (
        this.product.tables.find(
          table => table.id === hstack.horizontalStack[0].referencedId
        ).title &&
        this.product.tables.find(
          table => table.id === hstack.horizontalStack[0].referencedId
        ).title.length > 0 &&
        this.product.tables
          .find(table => table.id === hstack.horizontalStack[0].referencedId)
          .title
      )
      // return hstack
    },
    tableValidator(hstack) {
      let valid =
        this.product.tables.find(
          table => table.id === hstack.horizontalStack[0].referencedId
        ).title &&
        this.product.tables.find(
          table => table.id === hstack.horizontalStack[0].referencedId
        ).title.length > 0
      return valid
    }
  },
  watch: {
    secondaryTabs() {
      this.tab = 0
    },
    secondaryTabsAux() {
      this.tab = 0
    }
  },
  created() {
    this.xlsPropsHandler()
  },
  beforeUpdate() {
    this.xlsPropsHandler()
  }
}
</script>
<style scoped>
.v-tab {
  text-transform: none !important;
  font-weight: bold;
}
.table-title {
  font-size: 1.35rem;
}
.margin-bottom {
  /* font-size: 22px; */
  margin-bottom: -7px;
}
</style>
