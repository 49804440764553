<template>
  <div class="d-flex flex-row align-center">
    <v-text-field
      v-model="select"
      :label="filter.name"
      append-icon="mdi-magnify"
      persistent-hint
      return-object
      single-line
      clearable
      v-if="filter.filterType === 'text'"
      @input="emitSearchValue"
      @keydown.esc="emitSearchValueESC"
    />
  </div>
</template>
<script>
export default {
  props: {
    filter: {
      type: [Object, Array, String],
    },
  },
  data() {
    return {
      select: ''
    }
  },
  methods: {
    emitSearchValue() {
      this.$emit('emitSearchValue', this.select)
    },
    emitSearchValueESC() {
      this.select = null
      this.$emit('emitSearchValue', this.select)
    }
  }
  
}
</script>
<style lang="sass">
  
</style>