<template>
  <v-row
    v-if="hstack.horizontalStack[0].referencedSectionPath === 'endpointSources'"
    class="my-4 ml-1 mb-10"
    dense
  >
    <v-col v-if="filterPropHandler('dateRange')" cols="12" :md="3" class="mb-2">
      <date-range-filter
        :filter="filterPropHandler('dateRange')"
        @emitRangeValue="handleEmitRangeValue($event)"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="12" :md="3" class="mb-2">
      <search-filter
        :filter="filterPropHandler('all')"
        @emitSearchValue="handleEmitSearchValue($event)"
      />
    </v-col>
    <v-col cols="12" md="12" class="mb-8">
      <!-- Table Title  -->
      <span class="table-title black--text font-weight-bold d-flex flex-row align-center justify-space-between">
        {{ title }}
        <export-button
          :fileName="fileName"
          :titles="[titleTables]"
          :dataToExport="[allItems]"
          :totals="totals"
          class="d-print-none"
        ></export-button>
      </span>
    </v-col>
    <v-col v-if="genericData.header" cols="12">
      <table-component-generic-source
        :table="genericData"
        :loading="loading"
        :search="name"
      />
    </v-col>
  </v-row>
</template>
<script>
import DateRangeFilter from '@/views/home/partials/DateRangeFilter'
//import TransactionFilter from '@/views/home/partials/TransactionFilter'
import SearchFilter from '@/views/home/partials/SearchFilter'
import ExportButton from '@/components/commons/ExportButton'
import TableComponentGenericSource from '@/views/home/partials/TableComponentGenericSource'
//import PerformanceBarChart from '@/views/home/partials/PerformanceBarChart'
import GenericSourceService from '@/services/genericSource'
import { mapState, mapActions } from 'vuex'
import { getItems } from '@/utils/itemsToExport'
import { getTotal } from '@/utils/itemsToExport'

export default {
  components: {
    ExportButton,
    DateRangeFilter,
    //TransactionFilter,
    SearchFilter,
    TableComponentGenericSource,
    //PerformanceBarChart
  },
  props: {
    hstack: {
      type: [String, Number, Object, Boolean]
    },
    product: {
      type: Object
    },
    endpointSources: {
      type: Array
    }
  },
  data() {
    return {
      genericData: {},
      activityType: {},
      dateRange: {},
      name: '',
      loading: false,
      first_load: true,
      allItems: [],
      titleTables: [],
      totals: [],
      fileName: '',
      mixedChart: {}
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    tablePropHandler(hstack) {
      return this.product.tables[hstack.horizontalStack[0].referencedId]
    },
    tableTitleHandler(hstack) {
      return (
        this.product.tables[hstack.horizontalStack[0].referencedId].title &&
        this.product.tables[
          hstack.horizontalStack[0].referencedId
        ].title.toLowerCase()
      )
    },
    filterPropHandler(filterArgument) {
      return this.genericEndpointSource?.endpointFilters?.find(el => el.filterArgument === filterArgument)
    },
    async getGenericData() {
      this.setLoading(true)
      try {
        
        const fullURL = 'https:/'+this.genericEndpointSource.endpoint
        const url = new URL(fullURL)
        var searchParams = new URLSearchParams(url.search);
        const params = Object.fromEntries(searchParams.entries())
        params.endpoint = this.endpoint
        params.dateRange = this.dateRange?.value

        const { data } = await GenericSourceService.fetch(params)
        this.genericData = data

        this.xlsTitleTablesHandler()
        this.xlsAllItemssHandler()
        this.xlsTotalsHandler()
        this.xlsfileNameHandler()
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.setLoading(false)
      }
    },
    xlsAllItemssHandler() {
      this.allItems = getItems(this.genericData)
      this.allItems = [...flatten(this.allItems, 2)]
      const seen = new Set()
      this.allItems = this.allItems.filter(el => {
        const duplicate = seen.has(el)
        seen.add(el)
        return !duplicate
      })
      function* flatten(array, depth) {
        if (depth === undefined) {
          depth = 1
        }
        for (const item of array) {
          if (Array.isArray(item) && depth > 0) {
            yield* flatten(item, depth - 1)
          } else {
            yield item
          }
        }
      }

      
    },
    xlsTotalsHandler() {
      this.totals = []
      this.totals.push([getTotal(this.genericData)])
      this.totals.flat(1)
    },
    xlsTitleTablesHandler() {
      this.titleTables = []
      this.genericData?.header?.forEach(table => {
        this.titleTables.push([{ title: table.data }])
      })
      this.titleTables.flat(1)
    },
    xlsfileNameHandler() {
      const timestamp = Date.now()
      const humanReadableDateTime = new Date(timestamp).toDateString()

      this.fileName = `${this.account.code}-${
        this.genericData.title
      }-${humanReadableDateTime}`

    },
    handleEmitTransactionValue(select) {
      this.activityType = select
      this.getGenericData()
    },
    handleEmitRangeValue(select) {
      this.dateRange = select
      this.getGenericData()
    },
    handleEmitSearchValue(select) {
      this.name = select
    }
  },
  computed: {
    ...mapState('account', ['account']),
    ...mapState('language', ['languages', 'idiom']),

    title() {
      return this.genericData?.title
    },
    genericEndpointSource() {
      return this.endpointSources.find(item => item.id == this.hstack.horizontalStack[0].referencedId)
    },
    endpoint() {
      const fullURL = this.genericEndpointSource.endpoint
      let splitURL = fullURL.split('/')
      splitURL = splitURL[1].split('?')
      return splitURL[0]
    }
  },
  watch: {
    async genericEndpointSource() {
      await this.getGenericData()
    }
  },
  mounted() {
    this.getGenericData()
  }
}
</script>
<style lang=""></style>
