<template>
  <div
    v-if="chartsOut"
    class="d-flex flex-row align-center justify-lg-space-around"
    style="width: 100%;"
    ref="parent"
  >
    <div
      class="px-0 px-md-2 d-flex flex-column"
      v-bind="$attrs"
      v-on="$listeners"
      :ripple="{ class: `primary--text` }"
      style="max-height: 240px;"
    >
      <div
        class="px-0 px-md-2 my-2 mx-2"
        :ripple="{ class: `primary--text` }"
        :style="minWidth"
      >
        <!-- <v-card-title
          class="
            text-title 
            text-capitalize
            primary--text
            font-weight-bold
            d-flex
            justify-center
            align-start
            pt-0 
            pb-4
            mt-0
          "
        >
          <div>
            {{ title.toLowerCase() }}
          </div>
        </v-card-title> -->
        <div class="mt-0">
          <tree-map-chart
            v-if="toggle_chart === 'Treemap'"
            :data="treemap.datatest"
            :options="treemap.options"
            :styles="myStyles"
          ></tree-map-chart>
          <donut-chart
            v-if="toggle_chart === 'Dona'"
            :data="datacollection"
            :options="options"
            :styles="myStyles"
          ></donut-chart>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-start justify-start">
      <chart-selector
        :align="'flex-column'"
        @emitChartSelector="handleEmitChartSelector($event)"
      />
    </div>
  </div>
</template>

<script>
import TreeMapChart from '@/components/commons/TreeMapChart'
import DonutChart from '@/components/commons/DonutChart'
import chartSelector from '@/views/home/partials/chartSelector'
// import { getChartColors } from '@/utils'

export default {
  components: { TreeMapChart, DonutChart, chartSelector },
  props: {
    title: {
      type: [String, Number, Object],
      required: true
    },
    index: {
      type: [String, Number, Object],
      required: true
    },
    product: {
      type: [String, Number, Object],
      required: true
    },
    chartsOut: {
      type: [String, Number, Object, Boolean]
    }
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
        // position: 'relative',
        padding: '0px',
        // width: if (true) '600px',
        // display: 'flex'
        margin: 'auto'
      }
    },
    myWidth() {
      if (this.toggle_chart === 'Dona') {
        return '600px'
      } else {
        return '600px'
      }
    },
    minWidth() {
      // if (this.$vuetify.breakpoint.xlOnly) {
      //   return 'min-width: 1250px'
      // }
      // if (this.$vuetify.breakpoint.lgAndUp) {
      //   return 'min-width: 900px'
      // }
      // if (this.$vuetify.breakpoint.mdAndUp) {
      //   return 'min-width: 600px'
      // } else {
      //   return 'min-width: 1250px'
      // }
      // const viewportHeight = window.innerHeight
      // switch (true) {
      //   case viewportHeight < 640:
      //     return 'min-width: 690px'
      //   case viewportHeight < 700:
      //     return 'min-width: 690px'
      //   case viewportHeight < 800:
      //     return 'min-width: 690px'
      //   case viewportHeight < 900:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1000:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1100:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1500:
      //     return 'min-width: 1250px'
      //   case viewportHeight < 1800:
      //     return 'min-width: 1250px'
      //   case viewportHeight < 2200:
      //     return 'min-width: 1250px'
      //   default:
      //     return 'min-width: 1920px'
      // }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'min-width: 200px'
        case 'sm':
          return 'min-width: 400px'
        case 'md':
          return 'min-width: 490px'
        case 'lg':
          return 'min-width: 650px'
        case 'xl':
          return 'min-width: 1250px'
        default:
          return 'min-width: 750px'
      }
    }
  },
  methods: {
    fillData() {
      let pie = this.product.pies[this.index]
      this.datacollection.title = pie.title
      // const chartColors = getChartColors()
      pie.pieces
        .sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent))
        .forEach((item, index) => {
          this.datacollection.labels.push(item.label.data)
          const amount = parseFloat(item.value.data.replace(/,/g, ''))
          this.datacollection.datasets[this.index].data.push(amount)

          // const indexColor = this.getRandomColor(chartColors.length - 1)
          const bgColor = this.chartCorlorArray[index]
          this.datacollection.datasets[this.index].backgroundColor.push(bgColor)
          this.datacollection.datasets[this.index].borderWidth = 1
          this.datacollection.datasets[this.index].hoverBorderColor.push(
            bgColor
          )
          this.datacollection.datasets[this.index].hoverBorderWidth = 3
          this.datacollection.datasets[this.index].borderColor = '#fff'
        })
    },
    getRandomColor(max) {
      const min = 0
      return Math.floor(Math.random() * (+max - +min)) + +min
    },
    fillDataTreeMap() {
      const pie = this.product.pies[this.index]
      this.treemap.datatest.title = pie.title
      pie.pieces.forEach(piece => {
        this.treemap.datatest.datasets[0].tree.push({
          value: parseFloat(piece.value.data.replace(/,/g, '')),
          title: piece.label.data,
          percent: piece.percent,
          title_short:
            piece.percent < 0.08
              ? piece.label.data.slice(0, 2) + '...'
              : piece.label.data,
          percent_str: `${Math.round(piece.percent * 100) + '%'}`
        })
      })
    },
    positionDonutLegend() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'right'
      } else {
        return 'left'
      }
    },
    handleEmitChartSelector(toggle_chart) {
      this.toggle_chart = toggle_chart
    }
  },
  data() {
    return {
      height: 195,
      selected: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          fullSize: true,
          align: 'end',
          position: this.positionDonutLegend(),
          labels: {
            padding: 14.5,
            fontFamily: 'Lato',
            fontSize: 16,
            fontWeight: 'bold',
            boxWidth: 20,
            boxHeight: 20
          },
          onHover: function(e) {
            e.target.style.cursor = 'pointer'
          }
        },
        hover: {
          onHover: function(e) {
            let point = this.getElementAtEvent(e)
            if (point.length && point[0] === undefined)
              e.target.style.cursor = 'pointer'
            else e.target.style.cursor = 'default'
          }
        },
        tooltips: {
          displayColors: true,
          titleFontFamily: 'Lato',
          callbacks: {
            title: (item, data) => {
              return data.labels[item[0].index]
            },
            label: function(item, data) {
              let dataset = data.datasets[item.datasetIndex]
              let dataItem = dataset.data[item.index]
              let value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(dataItem)
              const sum = dataset.data.reduce((a, b) => a + b, 0)
              let percentage = ((dataItem * 100) / sum).toFixed(0) + '%'
              return value + ' - ' + percentage
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: context => {
              return context.dataset.backgroundColor
            },
            color: 'white',
            // display: (context) => {
            //   const dataset = context.dataset
            //   const count = dataset.data.length
            //   const value = dataset.data[context.dataIndex]
            //   return value > count * 1.5
            // },
            font: {
              weight: 'bold',
              size: 10
            },
            padding: 2,
            textAlign: 'start',
            formatter: (value, context) => {
              const sum = context.dataset.data.reduce((a, b) => a + parseInt(b))
              let percentage = ((value * 100) / sum).toFixed(0) + '%'
              return percentage
            },
            anchor: 'end',
            clamping: true,
            align: 'end',
            offset: 2.5,
            display: 'auto'
          }
        },
        // Core options
        aspectRatio: 4 / 3,
        cutoutPercentage: 25,

        layout: {
          padding: 15
        },
        elements: {
          line: {
            fill: false
          },
          point: {
            hoverRadius: 7,
            radius: 5
          }
        }
      },
      datacollection: {
        title: '',
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            hoverBorderColor: [],
            borderWidth: 1,
            borderColor: '#fff'
          }
        ]
      },
      toggle_chart: 'Dona',
      chartCorlorArray: [
        '#0280C8',
        '#A2A3B0',
        '#9F4355',
        '#2AA152',
        '#D0BC9A',
        '#000000',
        '#6568B7',
        '#58D2E3',
        '#828282',
        '#E4BC00',
        /*
        old Colors
        '#0D47A1',
        '#AA00FF',
        '#6200EA',
        '#2962FF',
        '#00B0FF',
        '#00C853',
        '#AEEA00',
        '#FFAB00',
        '#FF6D00',
        '#80D8FF'
        */
      ],
      treemap: {
        // height: 373,
        height: 180,
        // width: 3,
        selected: null,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              fontFamily: 'Lato',
              boxWidth: 30
            },
            display: false
          },
          tooltips: {
            callbacks: {
              title: (item, data) => {
                return data.datasets[0].tree.sort(
                  (a, b) => parseFloat(b.value) - parseFloat(a.value)
                )[item[0].index].title
              },
              label: (item, data) => {
                let dataset = data.datasets[item.datasetIndex]
                let dataItem = dataset.data[item.index]
                let obj = dataItem._data
                let value = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(dataItem.v)
                let percent = obj.children[0].percent
                return value + ' - ' + Math.round(percent * 100) + '%'
              }
            }
          },
          // Core options
          // aspectRatio: 4 / 3,
          // cutoutPercentage: 50,
          layout: {
            padding: 0
          }
        },
        datatest: {
          datasets: [
            {
              borderWidth: 0,
              tree: [],
              key: 'value',
              groups: ['title_short'],
              borderColor: 'white',
              fontColor: 'white',
              fontSize: this.$vuetify.breakpoint.xlOnly ? 14 : 11,
              fontStyle: 600,
              fontFamily: 'Lato',
              backgroundColor: ctx => {
                return this.chartCorlorArray[ctx.dataIndex]
              }
            }
          ]
        }
      }
    }
  },
  created() {
    this.fillData()
    this.fillDataTreeMap()
    // console.log('dan', getComputedStyle(this.$refs.parent).width)
  },
  mounted() {
    // console.log('dan', getComputedStyle(this.$refs.parent).width)
  },
  watch: {
    product() {
      this.datacollection = {
        title: '',
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            hoverBorderColor: [],
            borderWidth: 1,
            borderColor: '#fff'
          }
        ]
      }
      this.treemap = {
        // height: 373,
        height: 180,
        // width: 3,
        selected: null,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              fontFamily: 'Lato',
              boxWidth: 30
            },
            display: false
          },
          tooltips: {
            callbacks: {
              title: (item, data) => {
                return data.datasets[0].tree.sort(
                  (a, b) => parseFloat(b.value) - parseFloat(a.value)
                )[item[0].index].title
              },
              label: (item, data) => {
                let dataset = data.datasets[item.datasetIndex]
                let dataItem = dataset.data[item.index]
                let obj = dataItem._data
                let value = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(dataItem.v)
                let percent = obj.children[0].percent
                return value + ' - ' + Math.round(percent * 100) + '%'
              }
            }
          },
          // Core options
          // aspectRatio: 4 / 3,
          // cutoutPercentage: 50,
          layout: {
            padding: 0
          }
        },
        datatest: {
          datasets: [
            {
              borderWidth: 0,
              tree: [],
              key: 'value',
              groups: ['title_short'],
              borderColor: 'white',
              fontColor: 'white',
              fontSize: this.$vuetify.breakpoint.xlOnly ? 14 : 11,
              fontStyle: 600,
              fontFamily: 'Lato',
              backgroundColor: ctx => {
                return this.chartCorlorArray[ctx.dataIndex]
              }
            }
          ]
        }
      }
      this.fillData()
      this.fillDataTreeMap()
    }
  }
  // beforeUpdate() {
  //   this.fillData()
  //   this.fillDataTreeMap()
  // }
}
</script>
<style lang="sass" scoped>

.product-card
  // width: 300px
  // width: 350px
  height: 205px

  &.v-card--link::before
    background: white

  &.active
    border: 1px solid #0077FF65
    &.v-sheet.v-card:not(.v-sheet--outlined)
      box-shadow: 0px 6px 10px #0077FF65

  &.v-sheet.v-card:not(.v-sheet--outlined)
    border: 1px solid #00000019
    box-shadow: 0px 6px 10px #00000019

  //font size 14 en body
  .text-title
    font-size: 1.1rem !important

  .text-label
    font-size: 0.75rem

  .text-currency
    font-size: 0.75rem

  .card_text
    min-height: 60%

@media only screen and (max-width: 768px)
  .product-card
    width: 300px

  .text-title
    font-size: 1.1rem

  .text-label
    font-size: 0.85rem

  .text-currency
    font-size: 0.85rem
</style>
