<template>
  <div class="d-flex flex-row align-center">
    <v-select
      v-model="select"
      :items="filter.filterValues"
      item-text="label"
      item-value="value"
      :label="filter.name"
      persistent-hint
      return-object
      single-line
      v-if="filter.filterType === 'dropdown'"
      @input="emitRangeValue"
    />
  </div>
</template>
<script>
export default {
  props: {
    filter: {
      type: [Object, Array, String]
    }
  },
  data() {
    return {
      select: ''
    }
  },
  methods: {
    emitRangeValue() {
      this.$emit('emitRangeValue', this.select)
    },
  }
}
</script>
<style lang="sass"></style>
