<template>
  <base-form-modal
    :title="$t('portal_account_state')"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    :textSave="$t('btn_download')"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('portal_account_status_year')"
            :rules="{
              required: true,
              length: 4,
              max_value: currentYear,
              min_value: 2012
            }"
            v-slot="{ errors }"
          >
            <v-select
              v-model="year"
              :items="years"
              :label="$t('portal_account_status_year')"
              :error-messages="errors[0]"
              @input="handleYear"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('portal_account_status_month')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="month"
              :items="allowedMonths"
              :label="$t('portal_account_status_month')"
              item-value="code"
              item-text="month"
              :error-messages="errors[0]"
            >
              <template #item="{ item }">
                <span>{{ $t(item.month) }}</span>
              </template>

              <template #selection="{ item }">
                <span>{{ $t(item.month) }}</span>
              </template>
            </v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </base-form-modal>
</template>

<script>
import BaseFormModal from '@/components/commons/BaseFormModal'
import AccountStatementService from '@/services/accountStatement'

import { mapState } from 'vuex'

export default {
  components: { BaseFormModal },
  data() {
    return {
      loading: false,
      years: [],
      year: null,
      month: '',
      months: [
        { code: '01', month: 'portal_month1' },
        { code: '02', month: 'portal_month2' },
        { code: '03', month: 'portal_month3' },
        { code: '04', month: 'portal_month4' },
        { code: '05', month: 'portal_month5' },
        { code: '06', month: 'portal_month6' },
        { code: '07', month: 'portal_month7' },
        { code: '08', month: 'portal_month8' },
        { code: '09', month: 'portal_month9' },
        { code: '10', month: 'portal_month10' },
        { code: '11', month: 'portal_month11' },
        { code: '12', month: 'portal_month12' }
      ]
    }
  },
  computed: {
    ...mapState('account', ['account']),

    currentYear() {
      return parseInt(this.$date().format('YYYY'))
    },
    lastYear() {
      return parseInt(this.$date()
        .subtract(1, 'year')
        .format('YYYY'))
    },
    currentMonth() {
      return this.$date().format('MM')
    },
    lastMonth() {
      return this.$date()
        .subtract(1, 'month')
        .format('MM')
    },
    allowedMonths() {
      let filteredMonths = this.months
      if (this.year == this.currentYear)
        filteredMonths = filteredMonths.filter(
          item => item.code < this.currentMonth
        )

      return filteredMonths
    }
  },
  async created() {
    this.getAvailableYears()

    if (this.currentMonth == 1) {
      this.year = this.lastYear
      this.month = '12'
    } else {
      this.year = this.currentYear
      this.month = this.lastMonth
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        const year = this.year.toString()
        const params = {
          url: this.account.code + this.month + this.$date(year).format('YY'),
          accountCode: this.account.code,
          month: this.month,
          year: this.year
        }
        const { data } = await AccountStatementService.download(params)

        this.downloadPDF(data)

        this.$dialog.notify.success(this.$t('portal_pdf_success'))

        this.$emit('submit', null)
      } catch (error) {
        if (this.year == this.currentYear && this.month == this.lastMonth)
          this.$dialog.notify.error(this.$t('portal_pdf_error'))
        else
          this.$dialog.notify.error(this.$t('portal_pdf_error2'))
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleYear() {
      this.month = ''
    },
    async downloadPDF(data) {
      const pdfStr = `data:application/pdf;base64,${data.pdf}`
      fetch(pdfStr)
      .then(response => response.arrayBuffer())
      .then(response => {
        const file = new Blob([response], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = data.fileName;
        link.click();
        link.remove();
        // limpiando memoria
        setTimeout(() => URL.revokeObjectURL(link.href), 7000)
      });
    },
    getAvailableYears() {
      let year = 2012
      for (year; year <= this.currentYear; year++) {
        this.years.push(year)
      }
    }
  }
}
</script>

<style></style>
