import XLSX from 'xlsx'
export function exportToCsv(data, titles, fileName, totals ) {
  const wb = XLSX.utils.book_new()
  
  let worksheet = null

  data.forEach((row, i) => {
    if (row.length) {
      if (i === 0)
        worksheet = XLSX.utils.sheet_add_json(wb,titles[0], {skipHeader: true}) 
      else if(titles[i])
        XLSX.utils.sheet_add_json(worksheet, titles[i], {skipHeader: true, origin: -1}); //TITLE

      XLSX.utils.sheet_add_json(worksheet, row, {skipHeader: false, origin: i === 0 ? 'A1' : -1}); //TABLE

      if( totals[i][0] !== undefined ){
        XLSX.utils.sheet_add_json(worksheet, totals[i], {skipHeader: true, origin: -1}); //TOTALS
      }
      create_gap_rows(wb, 3);
    }
  })

  XLSX.utils.book_append_sheet(wb, worksheet)
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

function create_gap_rows(wb, nrows) {
  var ref = XLSX.utils.decode_range(wb["!ref"]);       // get original range
  ref.e.r += nrows;                                    // add to ending row
  wb["!ref"] = XLSX.utils.encode_range(ref);           // reassign row
}