<template>
  <v-widget :paddingTop="`pt-1`">
    <template #content>
      <v-tabs center-active v-model="tab" show-arrows>
        <v-tab
          v-for="tab in productTabValidator"
          :key="tab.index"
          @click="handleChangedTab"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items touchless v-model="tab">
        <v-tab-item
          v-for="(tab, index) in productTabValidator"
          :key="tab.index"
        >
          <generic-tab-item
            :productTab="tab"
            :tabIndex="index"
            :product="product"
            :selectedProduct="handleSelected(tab, index)"
            :selected="selected"
            :secondaryTabs="secondaryTabs"
          />
        </v-tab-item>
      </v-tabs-items>
      <!-- DETAILS -->
    </template>
  </v-widget>
</template>

<script>
import VWidget from '@/components/commons/VWidget'
import GenericTabItem from './partials/GenericTabItem.vue'

export default {
  components: { VWidget, GenericTabItem },
  props: {
    product: {
      type: Object,
      required: true
    },
    indexProp: {
      type: [String, Number, Object, Boolean]
    }
  },
  data() {
    return {
      tab: null,
      productIndex: 0,
      selectedProduct: 0,
      selected: 0,
      secondaryTabs: false
    }
  },
  methods: {
    handleChangedTab() {
      this.selected = 0
      this.secondaryTabs = !this.secondaryTabs
    },
    handleSelected(tab, index) {
      if (index === 1) {
        let value = false

        for (let index = 0; index < tab.referencedProducts.length; index++) {
          const product = this.product.products.find(
            product =>
              product.id === tab.referencedProducts[index].referencedId
          )

          let referencedId = null
          if (product && product.verticalInfo?.length > 0) {
            referencedId = 
            product.verticalInfo[0].verticalStack[0].horizontalStack[0].referencedId
          }

          let test = this.product.tables.find(
            table =>
              table.id === referencedId
          )
          if (test !== undefined && test.title && test.title.length > 0) {
            value = index
            break
          }
        }
        if (value) {
          return this.product.products.indexOf(
            this.product.products.find(
              product =>
                product.id === tab.referencedProducts[value].referencedId
            )
          )
        }
      } else {
        return this.product.products.indexOf(
          this.product.products.find(
            product => product.id === tab.referencedProducts[0].referencedId
          )
        )
      }
      return this.product.products.indexOf(
        this.product.products.find(
          product => product.id === tab.referencedProducts[0].referencedId
        )
      )
    }
  },
  watch: {
    indexProp() {
      this.tab = null
      this.productIndex = 0
      this.selectedProduct = 0
      this.selected = 0
      this.secondaryTabs = !this.secondaryTabs
    }
  },
  computed: {
    productTabValidator() {
      //valida si el tab tiene tablas válidas que se pueden renderizar
      let array = []
      for (let i = 0; i < this.product.productTabs.length - 1; i++) {
        for (
          let j = 0;
          j < this.product.productTabs[i].referencedProducts.length;
          j++
        ) {
          let table = this.product.tables.find(
            table =>
              table.id ===
              this.product.products.find(
                product =>
                  product.id ===
                  this.product.productTabs[i].referencedProducts[j].referencedId
              ).verticalInfo[0].verticalStack[0].horizontalStack[0].referencedId
          )
          table.title &&
            table.title.length > 0 &&
            table.sections &&
            table.sections.length > 0 &&
            !array.includes(this.product.productTabs[i]) &&
            array.push(this.product.productTabs[i])
        }
      }

      // Tab de rendimiento, siempre es presente
      this.product.productTabs[3] && array.push(this.product.productTabs[3])

      return array
    }
  }
}
</script>

<style scoped>
.v-tab {
  text-transform: none;
  font-weight: bold;
}
</style>
