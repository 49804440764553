<template>
  <v-widget dark class="pa-4 pb-0 " v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12" class="d-flex flex-row flex-sm-row">
          <div class="mr-sm-8">
            <div class="text-subtitle font-weight-medium mb-2">
              {{ $t('portal_total_assets') }}
            </div>
            <p class="text-title black--text font-weight-bold">
              <span
                :class="
                  parseInt(netAssets, 10) >= 0 ? 'success--text' : 'error--text'
                "
              >
                $
                {{ netAssets | currency }}
              </span>
            </p>
          </div>
          <div class="ml-8 ml-sm-0">
            <div class="text-subtitle font-weight-medium mb-2">
              {{ $t('portal_today_change') }}
            </div>
            <p class="text-title font-weight-bold text-exchange">
              <span
                :class="
                  parseInt(lastDayChangeUSD, 10) >= 0
                    ? 'success--text'
                    : 'error--text'
                "
              >
                $
              </span>
              <span
                :class="
                  parseInt(lastDayChangeUSD, 10) >= 0
                    ? 'success--text'
                    : 'error--text'
                "
              >
                {{ lastDayChangeUSD | currency }}
              </span>
              <span
                :class="
                  parseInt(lastDayChangeUSD, 10) >= 0
                    ? 'success--text'
                    : 'error--text'
                "
              >
                /
              </span>
              <!-- {{ lastDayChangePercent | percent }} -->
              <span
                :class="
                  parseInt(lastDayChangeUSD, 10) >= 0
                    ? 'success--text'
                    : 'error--text'
                "
              >
                {{ lastDayChangePercent }} %
              </span>
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row dense class="d-flex flex-row test">
        <v-col cols="5" class="d-flex justify-start last-line-margin">
          <span class="text-description primary--text text-decoration-none">
            {{ $t('portal_info_at') }}
            {{ lastWorkingDate }}
          </span>
        </v-col>
        <v-col
          cols="7"
          class="d-flex gap justify-end justify-sm-end align-start last-line-margin d-print-none px-4 px-md-0"
        >
          <!-- STATEMENT ACCOUNTS -->
          <a
            @click="handleAccountStatusModal"
            class="text-decoration-none mr-sm-2 mr-md-6 test2"
            v-if="hasPermission('view-statement-accounts')"
          >
            <v-icon
              class="mr-1 mr-sm-2"
              v-text="'mdi-file-download'"
              color="primary"
              small
            />
            <span class="text-description">{{
              $t('portal_account_state')
            }}</span>
          </a>
          <!-- INVOICES -->
          <a
            @click="handleInvoiceModal"
            class="text-decoration-none mr-sm-2 mr-md-6 test2"
            v-if="hasPermission('view-statement-accounts')"
          >
            <v-icon
              class="mr-1 mr-sm-2"
              v-text="'mdi-file-document'"
              color="primary"
              small
            />
            <span class="text-description">{{ $t('portal_invoices') }}</span>
          </a>
          <!-- PRINT -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                @click="handlePrint"
                class="text-decoration-none mr-sm-2 mr-md-6 test2"
                v-if="hasPermission('view-account-print')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  class="mr-1 mr-sm-2"
                  v-text="'mdi-printer'"
                  color="primary"
                  small
                />
                <span class="text-description">{{ $t('portal_print') }}</span>
              </a>
            </template>
            <span>{{ $t('portal_print_message') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
  </v-widget>
</template>

<script>
import VWidget from '@/components/commons/VWidget'
import LastDayPerformanceService from '@/services/lastDayPerformance'
import StatusAccountModal from './StatusAccountModal'
import InvoiceModal from './InvoiceModal'
import { mapState } from 'vuex'
import { hasPermission } from '@/utils'

export default {
  components: { VWidget },
  props: {},
  computed: {
    ...mapState('account', ['account']),
    ...mapState('language', ['languages', 'idiom']),
    ...mapState('auth', ['user']),
    items() {
      return this.languages
        .filter(item => item.code !== this.idiom.code)
        .map(item => {
          return {
            ...item,
            title: item.name
          }
        })
    },
    dateYesterday() {
      let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date())
      let month =
        yesterday.getMonth() + 1 < 10
          ? `0${yesterday.getMonth() + 1}`
          : yesterday.getMonth() + 1 //months from 1-12
      let day =
        yesterday.getDate() < 10
          ? `0${yesterday.getDate()}`
          : yesterday.getDate()
      let year = yesterday.getFullYear()
      if (this.idiom.code === 'en') {
        return `${month}/${day}/${year}c`
      } else {
        return `${day}/${month}/${year}c`
      }
    }
  },
  data() {
    return {
      netAssets: 0,
      totalAssets: 0,
      lastDayChangeUSD: 0,
      lastDayChangePercent: 0,
      lastWorkingDate: '',
      data: {},
      loading: false
    }
  },
  async created() {
    this.loading = true
    try {
      const data = await LastDayPerformanceService.find(this.account.code)
      this.netAssets = data.netAssets
      this.totalAssets = data.totalAssets
      this.lastDayChangeUSD = data.lastDayChangeUSD
      this.lastDayChangePercent = data.lastDayChangePercent
      this.lastWorkingDate = data.lastWorkingDate
      this.data = data
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  watch: {
    async account() {
      await this.populateProducts()
    }
  },
  methods: {
    async populateProducts() {
      this.loading = true
      try {
        const data = await LastDayPerformanceService.find(this.account.code)
        this.netAssets = data.netAssets
        this.totalAssets = data.totalAssets
        this.lastDayChangeUSD = data.lastDayChangeUSD
        this.lastDayChangePercent = data.lastDayChangePercent
        this.lastWorkingDate = data.lastWorkingDate
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    hasPermission(permission) {
      return hasPermission(this.user, permission)
    },

    async handleAccountStatusModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        // scrollable: true,
        transition: 'dialog-top-transition',
        showClose: false
      }

      await this.$dialog.showAndWait(StatusAccountModal, params)
    },

    async handleInvoiceModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        transition: 'dialog-top-transition',
        showClose: false
      }

      await this.$dialog.showAndWait(InvoiceModal, params)
    },

    handlePrint() {
      window.print()
    }
  }
}
</script>

<style lang="sass" scoped>
.gap
  gap: 2rem

.test
  margin-top: -20px
.text-exchange
  color: black


.text-description
  font-size: 0.714rem
  text-align: center
  line-height: normal


.text-subtitle
  font-size: 1rem
  color: #686F8D


.text-title
  font-size: 1rem

.date
  color: #00559e

.last-line-margin
  margin-top: 13.8px

.test2
  display: flex
  flex-direction: column



@media only screen and (min-width: 500px)
  .test2
    display: flex
    flex-direction: row

  .text-description
    font-size: 0.9rem

  .text-subtitle
    font-size: 1rem


  .text-title
    font-size: 1.714rem

@media screen and (min-width: 600px)
  .gap
    gap: 0
  .text-description
    font-size: 1rem
</style>
