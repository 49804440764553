<script>
// import { Doughnut } from 'vue-chartjs'
// import ChartDataLabels from 'chartjs-plugin-datalabels'
// export default {
//   props: {
//     data: {
//       type: Object,
//       default: () => {}
//     },
//     options: {
//       type: Object,
//       default: () => {}
//     }
//   },
//   extends: Doughnut,
//   mounted() {
// this.addPlugin(ChartDataLabels)
//     this.renderChart(this.data, this.options)
//   }
// }

// 1. Import Chart.js so you can use the global Chart object
// import Chart from 'chart.js'
// 2. Import the `generateChart()` method to create the vue component.
import { generateChart } from 'vue-chartjs'

// 3. Import the treemap package (not sure what exports are in the package. You will need to look it up

import 'chartjs-chart-treemap'

// import ChartDataLabels from 'chartjs-plugin-datalabels'

const TreeMap = generateChart('tree-map', 'treemap')

export default {
  extends: TreeMap,
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    // this.addPlugin(ChartDataLabels)
    this.renderChart(this.data, this.options)
  },
  watch: {
    data() {
      this.renderChart(this.data, this.options)
    }
  }
}
</script>
<style></style>
