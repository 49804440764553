var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-modal',{attrs:{"title":_vm.$t('portal_invoices'),"loading":_vm.loading,"hide-submit":""},on:{"close":function($event){return _vm.$emit('submit', null)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('portal_account_status_year'),"rules":{
            required: true,
            length: 4,
            max_value: _vm.currentYear,
            min_value: 2012
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.years,"label":_vm.$t('portal_account_status_year'),"error-messages":errors[0],"hide-details":"auto"},on:{"input":_vm.handleYear},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('portal_account_status_month'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.allowedMonths,"label":_vm.$t('portal_account_status_month'),"item-value":"code","item-text":"month","error-messages":errors[0],"hide-details":"auto"},on:{"input":_vm.handleMonth},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.month)))])]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.month)))])]}}],null,true),model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table-invoice-modal",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":999,"no-data-text":_vm.$t('general_no_data_label'),"item-key":"name","hide-default-footer":"","show-select":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end justify-md-center action-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({staticClass:"mr-4",on:{"click":function($event){return _vm.handleViewPDF(item.pdf)}}},on),[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-file-pdf-box')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('portal_invoice_view_pdf')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){return _vm.handleViewXML(item.xml)}}},on),[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-file-document')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('portal_invoice_view_xml')))])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]},proxy:true},{key:"footer-custom-button-after",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length === 0,"loading":_vm.downloading},on:{"click":_vm.handleDownload}},[(_vm.selected.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('btn_download'))+" ("+_vm._s(_vm.selected.length)+") ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('btn_download'))+" ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }