<template>
  <base-modal
    :title="item.data"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12" md="12" class="mb-8">
          <!-- Table Title  -->
          <span
            class="table-title black--text font-weight-bold"
          >
            {{ data.title }}
          </span>
        </v-col>
        <v-col cols="12">
          <table-component-generic-source :table="data" />
        </v-col>
      </v-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/commons/BaseModal'
//import InstrumentModalService from '@/services/instrumentModal'
import TableComponentGenericSource from '@/views/home/partials/TableComponentGenericSource'
import GenericSourceService from '@/services/genericSource'

import { mapState } from 'vuex'

export default {
  components: { BaseModal, TableComponentGenericSource },
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  computed: {
    ...mapState('account', ['account']),
    ...mapState('language', ['languages', 'idiom']),

    title() {
      return this.item.data
    },
    endpoint() {
      const fullURL = this.item?.dataActionLink?.endpoint
      let splitURL = fullURL.split('/')
      splitURL = splitURL[1].split('?')
      return splitURL[0]
    }
  },
  async created() {
    this.loading = true
    try {   
      const fullURL = 'https:/'+this.item.dataActionLink.endpoint
      const url = new URL(fullURL)
      var searchParams = new URLSearchParams(url.search);
      const params = Object.fromEntries(searchParams.entries())
      params.endpoint = this.endpoint 

      const { data } = await GenericSourceService.fetch(params)
      this.data = data
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    handleSubmit() {
      return this.$emit('submit', null)
    }
  },
}
</script>

<style></style>
