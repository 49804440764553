<template>
  <div>
    <!-- Cards -->
    <v-row class="">
      <v-col cols="12" class="">
        <!-- :class="
          tabIndex === 1 && $vuetify.breakpoint.lgAndUp && 'd-flex carousel'
        " -->
        <!-- class="py-10"  -->
        <v-slide-group
          :value="currentlySelected"
          mandatory
          center-active
          :show-arrows="arrownHandler"
          class="pt-3  pb-5 d-flex flex-row justify-center align-center justify-md-start"
        >
          <div
            class="d-flex flex-row justify-center align-center justify-md-start"
            :style="slideContainer"
          >
            <div
              v-for="ref in validCards"
              :key="ref.index"
              class="d-flex flex-row justify-center align-center justify-md-start"
              style="width: 100%;"
            >
              <v-slide-item v-slot="{ active, toggle }" class="">
                <product-card-horizontal
                  v-if="
                    productReferenced(ref).generalsDisposition === 'horizontal'
                  "
                  :title="productReferenced(ref).title"
                  :generals="productReferenced(ref).generals"
                  :class="active ? 'active' : ''"
                  :productReferenced="productReferenced(ref)"
                  class="ma-2"
                  @click="toggle"
                  @emitProductId="handleEmitProductId($event)"
                />
                <product-card
                  v-else
                  :title="productReferenced(ref).title"
                  :generals="productReferenced(ref).generals"
                  :class="active ? 'active' : ''"
                  :productReferenced="productReferenced(ref)"
                  :firstTabsCards="tabIndex === 0 ? true : false"
                  max-width="350"
                  class="ma-2"
                  @click="toggle"
                  @emitProductId="handleEmitProductId($event)"
                />
              </v-slide-item>
              <v-slide-item
                v-if="productReferencedPieValidator(productReferenced(ref))"
                v-slot="{ active, toggle }"
              >
                <charts
                  @click="toggle"
                  :class="active ? 'active' : ''"
                  :title="productReferencedPieTitle(productReferenced(ref))"
                  :product="product"
                  :index="productReferencedPieIndex(productReferenced(ref))"
                  :chartsOut="!chartsOut"
                />
              </v-slide-item>
            </div>
          </div>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row dense v-if="selectedProduct === 0 && chartsOut === true">
      <v-col class="d-flex flex-column justify-center align-center">
        <charts-out
          @click="toggle"
          title="Consolidado"
          :product="product"
          :index="0"
          :chartsOut="chartsOut"
        />
      </v-col>
    </v-row>
    <!-- Tables -->
    <generic-tables
      :product="product"
      :selected="selectedProduct"
      :secondaryTabs="secondaryTabs"
      :secondaryTabsAux="secondaryTabsAux"
      :endpointSources="product.endpointSources"
    />
  </div>
</template>

<script>
import ProductCard from '@/components/widgets/ProductCard'
import ProductCardHorizontal from '@/components/widgets/ProductCardHorizontal'
import Charts from '@/views/home/partials/Charts'
import ChartsOut from '@/views/home/partials/ChartsOut'
import GenericTables from '@/views/home/partials/GenericTables'
export default {
  name: 'GenericTabItem',
  components: {
    ProductCard,
    Charts,
    GenericTables,
    ChartsOut,
    ProductCardHorizontal
  },
  props: {
    productTab: {
      type: Object
    },
    product: {
      type: Object,
      required: true
    },
    selectedProduct: {
      type: [String, Object, Array, Number],
      default: '0'
    },
    selected: {
      type: [String, Object, Array, Number],
      default: 0
    },
    secondaryTabs: {
      type: [String, Object, Array, Number, Boolean]
    },
    tabIndex: {
      type: [String, Object, Array, Number]
    }
  },
  data() {
    return {
      secondaryTabsAux: false,
      validCardsArray: []
    }
  },
  computed: {
    currentlySelected() {
      return this.selected
    },
    products() {
      return this.product
    },
    arrownHandler() {
      let validCards = this.validCards
      if (validCards.length < 4) {
        return false
      } else {
        return `desktop`
      }
    },
    validCards() {
      if (this.tabIndex === 1) {
        let array = []
        // array.push(this.productTab.referencedProducts[0])
        // array.push(this.productTab.referencedProducts[1])
        for (
          let index = 0;
          index < this.productTab.referencedProducts.length;
          index++
        ) {
          const product = this.product.products.find(
            product =>
              product.id ===
              this.productTab.referencedProducts[index].referencedId
          )

          let referencedId = null
          if (product && product.verticalInfo?.length > 0) {
            referencedId =
              product.verticalInfo[0].verticalStack[0].horizontalStack[0]
                .referencedId
          }

          let valid = this.product.tables.find(
            table => table.id === referencedId
          )

          if (valid && valid.title?.length > 0) {
            array.push(this.productTab.referencedProducts[index])
          }
        }
        return array
      } else {
        return this.productTab.referencedProducts
      }
    },
    chartsOut() {
      return this.$vuetify.breakpoint.smAndDown
    },
    slideContainer() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'width: 100%;'
      }
      if (
        this.productTab.referencedProducts.find(
          el => el.referencedId === 'RendimientoProduct'
        )
      ) {
        return 'width: 100%;'
      }
      return 'width: auto;'
    }
  },
  methods: {
    productReferenced(ref) {
      return this.product.products.find(
        product => product.id === ref.referencedId
      )
    },
    productReferencedPieValidator(productReferenced) {
      return (
        productReferenced.horizontalInfo &&
        productReferenced.horizontalInfo.find(
          hinfo => hinfo.referencedSectionPath === 'pies'
        ) &&
        this.product.pies[0].pieces.length > 0
      )
    },
    productReferencedPieTitle(productReferenced) {
      return this.product.pies.find(
        pie =>
          pie.id ===
          productReferenced.horizontalInfo.find(
            href => href.referencedId === '0'
          ).referencedId
      ).title
    },
    productReferencedPieIndex(productReferenced) {
      return this.product.pies.indexOf(
        this.product.pies.find(
          pie =>
            pie.id ===
            productReferenced.horizontalInfo.find(
              href => href.referencedId === '0'
            ).referencedId
        )
      )
    },
    handleEmitProductId(id) {
      this.selectedProduct = this.product.products.indexOf(
        this.product.products.find(product => product.id === id)
      )
      this.secondaryTabsAux = !this.secondaryTabsAux
    }
  }
}
</script>

<style>
.carousel {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}
.carousel:not(:first-child) {
  /* margin-left: -800px; */
  gap: 20px;
}
.v-slide-group__wrapper {
  /* touch-action: auto !important; */
  touch-action: pan-y !important;

  /* touch-action: auto; */
  /* overflow: scroll; */
  scroll-behavior: smooth;
  /* scroll-snap-type: x mandatory; */
}
</style>
