<template>
  <div class="d-flex flex-column justify-center align-center">
    <div
      v-if="chartsOut"
      class="d-flex flex-column justify-center align-center"
    >
      <!-- <v-card-title
        class="text-title text-capitalize primary--text font-weight-bold"
      >
        {{ title.toLowerCase() }}
      </v-card-title> -->
      <div>
        <tree-map-chart
          v-if="toggle_chart === 'Treemap'"
          :data="treemap.datatest"
          :options="treemap.options"
          :styles="myStyles"
        ></tree-map-chart>
      </div>
      <div>
        <donut-chart
          v-if="toggle_chart === 'Dona'"
          :data="datacollection"
          :options="options"
          :styles="myStyles"
        ></donut-chart>
      </div>
      <div class="pt-7 pb-7">
        <chart-selector
          :align="'flex-row'"
          @emitChartSelector="handleEmitChartSelector($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@/components/commons/DonutChart'
import TreeMapChart from '@/components/commons/TreeMapChart'
import chartSelector from '@/views/home/partials/chartSelector'

// import { getChartColors } from '@/utils'

export default {
  components: { TreeMapChart, DonutChart, chartSelector },
  props: {
    title: {
      type: [String, Number, Object],
      required: true
    },
    index: {
      type: [String, Number, Object],
      required: true
    },
    product: {
      type: [String, Number, Object],
      required: true
    },
    chartsOut: {
      type: [String, Number, Object, Boolean]
    }
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
        // position: 'relative',
        padding: '0px',
        width: '298px',
        // display: 'flex'
        margin: 'auto'
      }
    },
    minWidth() {
      // if (this.$vuetify.breakpoint.xlOnly) {
      //   return 'min-width: 1250px'
      // }
      // if (this.$vuetify.breakpoint.lgAndUp) {
      //   return 'min-width: 900px'
      // }
      // if (this.$vuetify.breakpoint.mdAndUp) {
      //   return 'min-width: 600px'
      // } else {
      //   return 'min-width: 1250px'
      // }
      // const viewportHeight = window.innerHeight
      // switch (true) {
      //   case viewportHeight < 640:
      //     return 'min-width: 690px'
      //   case viewportHeight < 700:
      //     return 'min-width: 690px'
      //   case viewportHeight < 800:
      //     return 'min-width: 690px'
      //   case viewportHeight < 900:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1000:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1100:
      //     return 'min-width: 690px'
      //   case viewportHeight < 1500:
      //     return 'min-width: 1250px'
      //   case viewportHeight < 1800:
      //     return 'min-width: 1250px'
      //   case viewportHeight < 2200:
      //     return 'min-width: 1250px'
      //   default:
      //     return 'min-width: 1920px'
      // }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'min-width: 200px'
        case 'sm':
          return 'min-width: 400px'
        case 'md':
          return 'min-width: 600px'
        case 'lg':
          return 'min-width: 900px'
        case 'xl':
          return 'min-width: 1250px'
        default:
          return 'min-width: 750px'
      }
    }
  },
  methods: {
    fillData() {
      const pie = this.product.pies[this.index]
      this.datacollection.title = pie.title
      // const chartColors = getChartColors()

      pie.pieces
        .sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent))
        .forEach((item, index) => {
          this.datacollection.labels.push(item.label.data)
          const amount = parseFloat(item.value.data.replace(/,/g, ''))
          this.datacollection.datasets[this.index].data.push(amount)

          // const indexColor = this.getRandomColor(chartColors.length - 1)
          const bgColor = this.chartCorlorArray[index]
          this.datacollection.datasets[this.index].backgroundColor.push(bgColor)
          this.datacollection.datasets[this.index].borderWidth = 1
          this.datacollection.datasets[this.index].hoverBorderColor.push(
            bgColor
          )
          this.datacollection.datasets[this.index].hoverBorderWidth = 3
          this.datacollection.datasets[this.index].borderColor = '#fff'
        })
    },
    getRandomColor(max) {
      const min = 0
      return Math.floor(Math.random() * (+max - +min)) + +min
    },
    fillDataTreeMap() {
      const pie = this.product.pies[this.index]
      this.treemap.datatest.title = pie.title
      pie.pieces.forEach(piece => {
        this.treemap.datatest.datasets[0].tree.push({
          value: parseFloat(piece.value.data.replace(/,/g, '')),
          title: piece.label.data,
          percent: piece.percent,
          title_short:
            piece.percent < 0.08
              ? piece.label.data.slice(0, 2) + '...'
              : piece.label.data,
          percent_str: `${Math.round(piece.percent * 100) + '%'}`
        })
      })
    },
    positionDonutLegend() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'right'
      } else {
        return 'top'
      }
    },
    handleEmitChartSelector(toggle_chart) {
      this.toggle_chart = toggle_chart
    }
  },
  data() {
    return {
      height: 300,
      // width: 100,
      selected: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          fullSize: true,
          align: 'top',
          position: this.positionDonutLegend(),
          labels: {
            padding: 2,
            fontFamily: 'Lato',
            boxWidth: 20,
            boxHeight: 20
          },
          display: false
        },
        tooltips: {
          displayColors: true,
          titleFontFamily: 'Lato',
          callbacks: {
            title: (item, data) => {
              return data.labels[item[0].index]
            },
            label: function(item, data) {
              let dataset = data.datasets[item.datasetIndex]
              let dataItem = dataset.data[item.index]
              let value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(dataItem)
              const sum = dataset.data.reduce((a, b) => a + b, 0)
              let percentage = ((dataItem * 100) / sum).toFixed(0) + '%'
              return value + ' - ' + percentage
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: context => {
              return context.dataset.backgroundColor
            },
            color: 'white',
            // display: (context) => {
            //   const dataset = context.dataset
            //   const count = dataset.data.length
            //   const value = dataset.data[context.dataIndex]
            //   return value > count * 1.5
            // },
            font: {
              weight: 'bold',
              size: 10
            },
            padding: 2,
            textAlign: 'start',
            formatter: (value, context) => {
              const sum = context.dataset.data.reduce((a, b) => a + parseInt(b))
              let percentage = ((value * 100) / sum).toFixed(0) + '%'
              return percentage
            },
            anchor: 'end',
            clamping: true,
            align: 'end',
            offset: 2.5,
            display: 'auto'
          }
        },
        // Core options
        aspectRatio: 4 / 3,
        cutoutPercentage: 25,

        layout: {
          padding: 20
        },
        elements: {
          line: {
            fill: false
          },
          point: {
            hoverRadius: 7,
            radius: 5
          }
        }
      },
      datacollection: {
        title: '',
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            hoverBorderColor: [],
            borderWidth: 1,
            borderColor: '#fff'
          }
        ]
      },
      toggle_chart: 'Treemap',
      chartCorlorArray: [
        '#0D47A1',
        '#AA00FF',
        '#6200EA',
        '#2962FF',
        '#00B0FF',
        '#00C853',
        '#AEEA00',
        '#FFAB00',
        '#FF6D00',
        '#80D8FF'
      ],
      treemap: {
        // height: 373,
        height: 180,
        // width: 3,
        selected: null,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              fontFamily: 'Lato',
              boxWidth: 30
            },
            display: false
          },
          tooltips: {
            callbacks: {
              title: (item, data) => {
                return data.datasets[0].tree.sort(
                  (a, b) => parseFloat(b.value) - parseFloat(a.value)
                )[item[0].index].title
              },
              label: (item, data) => {
                let dataset = data.datasets[item.datasetIndex]
                let dataItem = dataset.data[item.index]
                let obj = dataItem._data
                let value = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(dataItem.v)
                let percent = obj.children[0].percent
                return value + ' - ' + Math.round(percent * 100) + '%'
              }
            }
          },
          // Core options
          // aspectRatio: 4 / 3,
          // cutoutPercentage: 50,
          layout: {
            padding: 0
          }
        },
        datatest: {
          datasets: [
            {
              borderWidth: 0,
              tree: [],
              key: 'value',
              groups: ['title_short'],
              borderColor: 'white',
              fontColor: 'white',
              fontSize: 10.5,
              fontStyle: 600,
              fontFamily: 'Lato',
              backgroundColor: ctx => {
                return this.chartCorlorArray[ctx.dataIndex]
              }
            }
          ]
        }
      }
    }
  },
  created() {
    this.fillData()
    this.fillDataTreeMap()
    // console.log('dan', getComputedStyle(this.$refs.parent).width)
  },
  mounted() {
    // console.log('dan', getComputedStyle(this.$refs.parent).width)
  },
  watch: {
    product() {
      ;(this.datacollection = {
        title: '',
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            hoverBorderColor: [],
            borderWidth: 1,
            borderColor: '#fff'
          }
        ]
      }),
        (this.treemap = {
          // height: 373,
          height: 180,
          // width: 3,
          selected: null,
          options: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              align: 'start',
              position: 'bottom',
              labels: {
                fontFamily: 'Lato',
                boxWidth: 30
              },
              display: false
            },
            tooltips: {
              callbacks: {
                title: (item, data) => {
                  return data.datasets[0].tree.sort(
                    (a, b) => parseFloat(b.value) - parseFloat(a.value)
                  )[item[0].index].title
                },
                label: (item, data) => {
                  let dataset = data.datasets[item.datasetIndex]
                  let dataItem = dataset.data[item.index]
                  let obj = dataItem._data
                  let value = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(dataItem.v)
                  let percent = obj.children[0].percent
                  return value + ' - ' + Math.round(percent * 100) + '%'
                }
              }
            },
            // Core options
            // aspectRatio: 4 / 3,
            // cutoutPercentage: 50,
            layout: {
              padding: 0
            }
          },
          datatest: {
            datasets: [
              {
                borderWidth: 0,
                tree: [],
                key: 'value',
                groups: ['title_short'],
                borderColor: 'white',
                fontColor: 'white',
                fontSize: 10.5,
                fontStyle: 600,
                fontFamily: 'Lato',
                backgroundColor: ctx => {
                  return this.chartCorlorArray[ctx.dataIndex]
                }
              }
            ]
          }
        })
      this.fillData()
      this.fillDataTreeMap()
    }
  }
}
</script>
<style lang="sass" scoped>
.test
  max-width: 290px

.product-card
  // width: 280px
  height: 340px

  &.v-card--link::before
    background: white

  &.active
    border: 1px solid #0077FF65
    &.v-sheet.v-card:not(.v-sheet--outlined)
      box-shadow: 0px 6px 10px #0077FF65

  &.v-sheet.v-card:not(.v-sheet--outlined)
    border: 1px solid #00000019
    box-shadow: 0px 6px 10px #0077ff65
    border: 1px solid #00000019
    box-shadow: 0px 6px 10px #00000019

  .text-title
    font-size: 1.714rem

  .text-currency
    font-size: 1.143rem

@media only screen and (min-width: 768px)
  .product-card
    width: 356px
    height: 240px

    .text-title
      font-size: 1.571rem

    .text-currency
      font-size: 1.143rem
</style>
