<template>
  <v-card
    class="product-card rounded-lg px-0 px-md-2 d-flex flex-column"
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="{ class: `primary--text` }"
    @click="emitProductId"
    :style="myStyles"
  >
    <v-card-title
      class="text-title text-capitalize primary--text font-weight-bold pt-1 pb-1 d-flex flex-row justify-start justify-md-start"
      >{{ title.toLowerCase() }}</v-card-title
    >
    <v-card-text
      v-if="$vuetify.breakpoint.mdAndUp"
      class="pt-1 d-flex flex-column justify-start justify-space-between card_text"
    >
      <v-row dense class="d-flex flex-row justify-space-between">
        <v-col
          v-for="g in generals"
          :key="g.index"
          cols="1"
          class="text-center d-flex flex-column justify-center"
        >
          <span class="text-label font-weight-bold ">
            {{ g.label.data }}
          </span>
        </v-col>
      </v-row>
      <v-row dense class="d-flex flex-row justify-space-between">
        <v-col
          v-for="g in generals"
          :key="g.index"
          cols="1"
          class="text-center d-flex flex-column justify-center"
        >
          <span
            class="text-currency font-weight-bold"
            :class="parseInt(g.value.data, 10) >= 0 ? '' : 'error--text'"
          >
            {{ g.value.data }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text
      v-else
      class="pt-1 d-flex flex-column justify-start justify-space-between card_text"
    >
      <v-row v-for="g in generals" :key="g.index" dense>
        <v-col cols="6" class="d-flex flex-column justify-center">
          <span class="text-label font-weight-bold">
            {{ g.label.data }}
          </span>
        </v-col>
        <v-col cols="6" class="text-right d-flex flex-column justify-center">
          <span
            class="text-currency font-weight-bold"
            :class="parseInt(g.value.data, 10) >= 0 ? '' : 'error--text'"
          >
            {{ g.value.data }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    generals: {
      type: [Array, Object],
      default: null
    },
    productReferenced: {
      type: [Array, Object, Number],
      default: null
    }
  },
  methods: {
    emitProductId() {
      this.$emit('emitProductId', this.productReferenced.id)
    }
  },
  computed: {
    myStyles() {
      return 'height: 100%;'
      /*
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'height: 103px;'
      } else {
        return 'height: 230px;'
      }
      */
    }
  },
  mouned() {
    this.emitProductId()
  }
}
</script>

<style lang="sass" scoped>
.product-card
  // width: 300px
  width: 100%
  // height: 100%

  &.v-card--link::before
    background: white

  &.active
    border: 1px solid #0077FF65
    &.v-sheet.v-card:not(.v-sheet--outlined)
      box-shadow: 0px 6px 10px #0077FF65

  &.v-sheet.v-card:not(.v-sheet--outlined)
    border: 1px solid #00000019
    box-shadow: 0px 6px 10px #00000019

  //font size 14 en body
  .text-title
    font-size: 1.35rem

  .text-label
    font-size: 0.75rem

  .text-currency
    font-size: 0.75rem

  .card_text
    min-height: 60%

@media only screen and (max-width: 768px)
  .product-card
    width: 300px

  .text-title
    font-size: 1.1rem

  .text-label
    font-size: 0.85rem

  .text-currency
    font-size: 0.85rem
</style>
