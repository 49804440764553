<template>
  <div>
    <mixed-bar-line-chart v-if="mixedChart.id" :mixedChart="mixedChart" />
  </div>
</template>

<script>
import MixedBarLineChart from '@/views/home/partials/MixedBarLineChart'
import PerformanceBarService from '@/services/performanceBars'
import { mapState } from 'vuex'

export default {
  components: {
    MixedBarLineChart
  },
  data() {
    return {
      mixedChart: {},
      firstLoad: true,
    }
  },
  computed: {
    ...mapState('account', ['account']),
    ...mapState('language', ['languages', 'idiom'])
  },
  async created() {
    await this.populatePerformanceBar()
  },
  methods: {
    async populatePerformanceBar() {
      try {
        const paramsChart = {
          accountCode: this.account.code,
          lang: this.idiom.code
        }
        const dataBar = await PerformanceBarService.fetch(paramsChart)
        this.mixedChart = dataBar.data
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } 
    },
  }
}
</script>

<style>

</style>