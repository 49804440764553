<template>
  <a
    class="text-decoration-none d-flex flex-row align-center justify-end mr-0"
    @click="handlerExport()"
  >
      <!-- v-text="'mdi-file-excel-outline'" -->

    <v-icon
      class="mr-1 mr-sm-2"
      v-text="'mdi-microsoft-excel'"
      color="primary"
      small
    />
    <span class="text-description">{{ $t('portal_export') }}</span>
  </a>
</template>
<script>
import { exportToCsv } from '@/utils/export'
export default {
  name: 'BaseButtonsList',
  props: {
    dataToExport: {
      type: Array,
      default: () => []
    },
    titles: {
      type: Array,
      default: () => []
    },
    fileName: {
      type: String,
      default: () => ''
    },
    totals: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handlerExport() {
      exportToCsv(this.dataToExport, this.titles, this.fileName, this.totals)
    }
  }
}
</script>

<style lang="sass" scoped>
.text-exchange
  color: #4caf50


.text-description
  font-size: 0.714rem


.text-subtitle
  font-size: 1rem
  color: #686F8D


.text-title
  font-size: 1.714rem


@media only screen and (min-width: 480px)
  .text-description
    font-size: 1rem


  .text-subtitle
    font-size: 1rem


  .text-title
    font-size: 1.714rem
</style>
