<template>
  <v-card
    class="product-card rounded-lg px-0 px-md-2 d-flex flex-column"
    :style="cardStyles"
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="{ class: `primary--text` }"
    @click="emitProductId"
  >
    <v-card-title
      class="text-title primary--text font-weight-bold pt-1 pb-1"
      >{{ title }}</v-card-title
    >
    <!-- style="height: 55%;" -->
    <v-card-text
      class="pt-1 d-flex flex-column justify-start justify-space-between card_text"
    >
      <v-row
        v-for="g in generals"
        :key="g.index"
        dense
        :class="!firstTabsCards && 'card-row'"
      >
        <v-col cols="6" class="d-flex flex-column justify-center">
          <span class="text-label font-weight-bold">
            {{ g.label.data }}
          </span>
        </v-col>
        <v-col cols="6" class="text-right d-flex flex-column justify-center">
          <span
            class="text-currency font-weight-bold"
            :class="parseInt(g.value.data, 10) >= 0 ? '' : 'error--text'"
          >
            {{ g.value.data }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    generals: {
      type: [Array, Object],
      default: null
    },
    productReferenced: {
      type: [Array, Object, Number],
      default: null
    },
    firstTabsCards: {
      type: [Boolean]
    }
  },
  computed: {
    cardStyles() {
      // return this.firstTabsCards ? 'height: 205px;' : 'height: 135px;'
      // return 'height: 170px;'
      return ''
    }
  },
  methods: {
    emitProductId() {
      this.$emit('emitProductId', this.productReferenced.id)
    }
  },
  mouned() {
    this.emitProductId()
  }
}
</script>

<style lang="sass" scoped>
.card-row
  // margin-top: -5px
.product-card
  height: 170px
  width: 320px
  min-width: 320px
  // height: 205px

  &.v-card--link::before
    background: white

  &.active
    border: 1px solid #0077FF65
    &.v-sheet.v-card:not(.v-sheet--outlined)
      box-shadow: 0px 6px 10px #0077FF65

  &.v-sheet.v-card:not(.v-sheet--outlined)
    border: 1px solid #00000019
    box-shadow: 0px 6px 10px #00000019

  //font size 14 en body
  .text-title
    font-size: 1.35rem

  .text-label
    font-size: 0.75rem

  .text-currency
    font-size: 0.75rem

  .card_text
    // min-height: 60%

@media only screen and (max-width: 768px)
  .product-card
    width: 300px
    height: 100%
    min-height: 170px

  .text-title
    font-size: 1.1rem

  .text-label
    font-size: 0.85rem

  .text-currency
    font-size: 0.85rem
</style>
