<template>
  <v-card tile class="modal">
    <v-toolbar dark color="primary">
      <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="handleClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="fill-height">
      <v-container fluid grid-list-xl pa-md-10>
        <template v-if="loading">
          <Loading :loading="loading" />
        </template>
        <template v-else>
          <slot name="content"></slot>
        </template>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Loading from '../commons/Loading'
export default {
  name: 'BaseModal',
  components: { Loading },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    textSave: {
      type: String,
      default: 'Cerrar'
    },
    iconSave: {
      type: String,
      default: 'mdi-send'
    }
  },
  methods: {
    handleClose() {
      this.$emit('close', false)
    }
  }
}
</script>
<style lang="sass">
.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh
</style>
