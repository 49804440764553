var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-group',{staticClass:"pt-3  pb-5 d-flex flex-row justify-center align-center justify-md-start",attrs:{"value":_vm.currentlySelected,"mandatory":"","center-active":"","show-arrows":_vm.arrownHandler}},[_c('div',{staticClass:"d-flex flex-row justify-center align-center justify-md-start",style:(_vm.slideContainer)},_vm._l((_vm.validCards),function(ref){return _c('div',{key:ref.index,staticClass:"d-flex flex-row justify-center align-center justify-md-start",staticStyle:{"width":"100%"}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
var active = ref$1.active;
var toggle = ref$1.toggle;
return [(
                  _vm.productReferenced(ref).generalsDisposition === 'horizontal'
                )?_c('product-card-horizontal',{staticClass:"ma-2",class:active ? 'active' : '',attrs:{"title":_vm.productReferenced(ref).title,"generals":_vm.productReferenced(ref).generals,"productReferenced":_vm.productReferenced(ref)},on:{"click":toggle,"emitProductId":function($event){return _vm.handleEmitProductId($event)}}}):_c('product-card',{staticClass:"ma-2",class:active ? 'active' : '',attrs:{"title":_vm.productReferenced(ref).title,"generals":_vm.productReferenced(ref).generals,"productReferenced":_vm.productReferenced(ref),"firstTabsCards":_vm.tabIndex === 0 ? true : false,"max-width":"350"},on:{"click":toggle,"emitProductId":function($event){return _vm.handleEmitProductId($event)}}})]}}],null,true)}),(_vm.productReferencedPieValidator(_vm.productReferenced(ref)))?_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
                var active = ref$1.active;
                var toggle = ref$1.toggle;
return [_c('charts',{class:active ? 'active' : '',attrs:{"title":_vm.productReferencedPieTitle(_vm.productReferenced(ref)),"product":_vm.product,"index":_vm.productReferencedPieIndex(_vm.productReferenced(ref)),"chartsOut":!_vm.chartsOut},on:{"click":toggle}})]}}],null,true)}):_vm._e()],1)}),0)])],1)],1),(_vm.selectedProduct === 0 && _vm.chartsOut === true)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},[_c('charts-out',{attrs:{"title":"Consolidado","product":_vm.product,"index":0,"chartsOut":_vm.chartsOut},on:{"click":_vm.toggle}})],1)],1):_vm._e(),_c('generic-tables',{attrs:{"product":_vm.product,"selected":_vm.selectedProduct,"secondaryTabs":_vm.secondaryTabs,"secondaryTabsAux":_vm.secondaryTabsAux,"endpointSources":_vm.product.endpointSources}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }