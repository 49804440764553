var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-widget',_vm._b({staticClass:"pa-4 pb-0 ",attrs:{"dark":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row flex-sm-row",attrs:{"cols":"12"}},[_c('div',{staticClass:"mr-sm-8"},[_c('div',{staticClass:"text-subtitle font-weight-medium mb-2"},[_vm._v(" "+_vm._s(_vm.$t('portal_total_assets'))+" ")]),_c('p',{staticClass:"text-title black--text font-weight-bold"},[_c('span',{class:parseInt(_vm.netAssets, 10) >= 0 ? 'success--text' : 'error--text'},[_vm._v(" $ "+_vm._s(_vm._f("currency")(_vm.netAssets))+" ")])])]),_c('div',{staticClass:"ml-8 ml-sm-0"},[_c('div',{staticClass:"text-subtitle font-weight-medium mb-2"},[_vm._v(" "+_vm._s(_vm.$t('portal_today_change'))+" ")]),_c('p',{staticClass:"text-title font-weight-bold text-exchange"},[_c('span',{class:parseInt(_vm.lastDayChangeUSD, 10) >= 0
                  ? 'success--text'
                  : 'error--text'},[_vm._v(" $ ")]),_c('span',{class:parseInt(_vm.lastDayChangeUSD, 10) >= 0
                  ? 'success--text'
                  : 'error--text'},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lastDayChangeUSD))+" ")]),_c('span',{class:parseInt(_vm.lastDayChangeUSD, 10) >= 0
                  ? 'success--text'
                  : 'error--text'},[_vm._v(" / ")]),_c('span',{class:parseInt(_vm.lastDayChangeUSD, 10) >= 0
                  ? 'success--text'
                  : 'error--text'},[_vm._v(" "+_vm._s(_vm.lastDayChangePercent)+" % ")])])])])],1),_c('v-row',{staticClass:"d-flex flex-row test",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-start last-line-margin",attrs:{"cols":"5"}},[_c('span',{staticClass:"text-description primary--text text-decoration-none"},[_vm._v(" "+_vm._s(_vm.$t('portal_info_at'))+" "+_vm._s(_vm.lastWorkingDate)+" ")])]),_c('v-col',{staticClass:"d-flex gap justify-end justify-sm-end align-start last-line-margin d-print-none px-4 px-md-0",attrs:{"cols":"7"}},[(_vm.hasPermission('view-statement-accounts'))?_c('a',{staticClass:"text-decoration-none mr-sm-2 mr-md-6 test2",on:{"click":_vm.handleAccountStatusModal}},[_c('v-icon',{staticClass:"mr-1 mr-sm-2",attrs:{"color":"primary","small":""},domProps:{"textContent":_vm._s('mdi-file-download')}}),_c('span',{staticClass:"text-description"},[_vm._v(_vm._s(_vm.$t('portal_account_state')))])],1):_vm._e(),(_vm.hasPermission('view-statement-accounts'))?_c('a',{staticClass:"text-decoration-none mr-sm-2 mr-md-6 test2",on:{"click":_vm.handleInvoiceModal}},[_c('v-icon',{staticClass:"mr-1 mr-sm-2",attrs:{"color":"primary","small":""},domProps:{"textContent":_vm._s('mdi-file-document')}}),_c('span',{staticClass:"text-description"},[_vm._v(_vm._s(_vm.$t('portal_invoices')))])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.hasPermission('view-account-print'))?_c('a',_vm._g(_vm._b({staticClass:"text-decoration-none mr-sm-2 mr-md-6 test2",on:{"click":_vm.handlePrint}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-1 mr-sm-2",attrs:{"color":"primary","small":""},domProps:{"textContent":_vm._s('mdi-printer')}}),_c('span',{staticClass:"text-description"},[_vm._v(_vm._s(_vm.$t('portal_print')))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('portal_print_message')))])])],1)],1)]},proxy:true}])},'v-widget',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }